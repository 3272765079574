import React from 'react';
import 'sympla-bootstrap/paper';
interface PaperProps {
  elevation?: string;
  className?: string;
  children: React.ReactNode;
}

const Paper: React.FC<PaperProps> = ({ elevation, children, className }: PaperProps) => {
  return (
    <sb-paper elevation={elevation} class={className}>
      {children}
    </sb-paper>
  );
};

Paper.defaultProps = {
  elevation: '1',
};

export default Paper;
