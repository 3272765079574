import React from 'react';
import { SubscriptionPage, SubscriptionItem } from '../../../model/subscription';
import { subscriptionsTableColumns, navigationOptions, customStyles } from './_data';
import DataTable from 'react-data-table-component';
import Spinner from '../../../components/Spinner';
import ExpandableDetails from './ExpandableDetails';
import './styles.scss';

interface TableProps {
  subscriptions: SubscriptionPage | null;
  loading: boolean;
  className?: string;
  currentPage?: number;
  onRowClicked: (row: SubscriptionItem, e?: MouseEvent) => void;
  onChangePage: (page: number, totalRows?: number) => void;
}
const SubscriptionsTable: React.FC<TableProps> = ({
  subscriptions,
  loading,
  className,
  currentPage,
  onChangePage,
  onRowClicked,
}) => {
  return (
    <DataTable
      className={className}
      columns={subscriptionsTableColumns}
      data={subscriptions?.content ?? []}
      paginationTotalRows={subscriptions?.totalElements}
      paginationPerPage={15}
      onChangePage={onChangePage}
      onRowClicked={onRowClicked}
      paginationComponentOptions={navigationOptions}
      paginationRowsPerPageOptions={[15]}
      expandableRowsComponent={<ExpandableDetails onDetailsClick={onRowClicked} />}
      paginationDefaultPage={currentPage}
      expandableRows
      pagination
      paginationServer
      responsive
      highlightOnHover
      pointerOnHover
      noHeader
      paginationResetDefaultPage
      progressPending={loading}
      progressComponent={<Spinner loading className="pages__spinner" />}
      customStyles={customStyles}
    ></DataTable>
  );
};

export default SubscriptionsTable;
