import { combineReducers, Reducer } from 'redux';
import { ActionTypes } from '../../actions/actionTypes';
import { PlanItem, PlanPage } from '../../model/plan';
import { Alert } from '../../model/general';

const page: Reducer<PlanPage | null> = (state = null, { type, payload }) => {
  switch (type) {
    case ActionTypes.PLAN_PAGE_LIST:
      return payload;
    default:
      return state;
  }
};

const plan: Reducer<PlanItem | null> = (state = null, { type, payload }) => {
  switch (type) {
    case ActionTypes.PLAN_ADD:
      return payload;
    case ActionTypes.PLAN_LOADING:
      return null;
    default:
      return state;
  }
};

const loading: Reducer<boolean> = (state = false, { type }) => {
  switch (type) {
    case ActionTypes.PLAN_LOADING:
      return true;
    case ActionTypes.PLAN_ERROR:
    case ActionTypes.PLAN_ADD:
      return false;
    default:
      return state;
  }
};

const pageLoading: Reducer<boolean> = (state = true, { type }) => {
  switch (type) {
    case ActionTypes.PLAN_PAGE_LOADING:
      return true;
    case ActionTypes.PLAN_PAGE_LIST:
      return false;
    default:
      return state;
  }
};

const pageAlert: Reducer<Alert | null> = (state = null, { type, payload }) => {
  switch (type) {
    case ActionTypes.PLAN_PAGE_ALERT:
      return payload;
    case ActionTypes.PLAN_PAGE_LOADING:
      return null;
    default:
      return state;
  }
};

const saving: Reducer<boolean> = (state = false, { type }) => {
  switch (type) {
    case ActionTypes.PLAN_SAVING:
      return true;
    case ActionTypes.PLAN_SAVED:
      return false;
    default:
      return state;
  }
};

const saved: Reducer<Alert | null> = (state = null, { type, payload }) => {
  switch (type) {
    case ActionTypes.PLAN_SAVED:
      return payload;
    case ActionTypes.RESET_PLAN_SAVED:
      return null;
    default:
      return state;
  }
};

const error: Reducer<Alert | null> = (state = null, { type, payload }) => {
  switch (type) {
    case ActionTypes.PLAN_ERROR:
      return payload;
    case ActionTypes.PLAN_LOADING:
      return null;
    default:
      return state;
  }
};

export default combineReducers({
  page,
  pageLoading,
  pageAlert,
  plan,
  loading,
  saving,
  saved,
  error,
});
