import React from 'react';
import ReactModal from 'react-modal';
import './styles.scss';
import SbTextField from 'sympla-bootstrap/text-field';
import { Redirect } from 'react-router-dom';
import cookie from 'react-cookies';
import Logo from '../../assets/logo.png';
import { connect } from 'react-redux';
import { login } from '../../actions/session';
import { StoreState } from '../../model/store';
import { RoutesEnum } from '../../enums/routes';
import { UserLoginResponse } from '../../model/session';
import Icon from '../../components/Icon';

const { 
  REACT_APP_USER_COOKIE_NAME,
  REACT_APP_BILETO_MANAGER_CDKLOGIN_URL,
  REACT_APP_ENV,
  REACT_APP_RECAPTCHA_KEY 
} = process.env;

interface LoginProps {
  isLogged: boolean;
  errorMessage: string | null;
  login: (response: UserLoginResponse) => void;
}

interface AuthComponent extends React.ReactElement {
  environment: string;
  recaptchaKey: string | undefined;
  onSignIn(data: any): void;
  onChangePassword(data: any): void;
  onError(data: any): void;
  onLoad(): void;
}

interface LoginState {
  openChangePassword: boolean;
  logged: boolean;
  loadingAuthComponent: boolean;
}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '32px 42px',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.85)',
  },
};

class Login extends React.Component<LoginProps, LoginState> {
  emailRef = React.createRef<SbTextField>();

  passwordRef = React.createRef<SbTextField>();

  symplaAuthRef = React.createRef<AuthComponent>();

  symplaAuthChangePasswordRef = React.createRef<AuthComponent>();

  loadingAuthComponent = true;

  constructor(props: any) {
    super(props);
    this.state = { 
      openChangePassword: false,
      logged: false,
      loadingAuthComponent: false,
    };

    this._openChangePassword = this._openChangePassword.bind(this);
    this._closeChangePassword = this._closeChangePassword.bind(this);
  }


  componentDidMount() {
    this.setState({ logged: Boolean(cookie.load(REACT_APP_USER_COOKIE_NAME)) });
    // this.emailRef.current?.addEventListener('keypress', this.onKeypress);
    // this.passwordRef.current?.addEventListener('keypress', this.onKeypress);

    this.initialCDKLoginWeb().then(() => {
      const symplaAuth = this.symplaAuthRef.current
    
      if (symplaAuth) {
        symplaAuth.environment = REACT_APP_ENV || "development";
        symplaAuth.recaptchaKey = REACT_APP_RECAPTCHA_KEY;
        symplaAuth.onSignIn = this._handleResponseLogin.bind(this);
        // symplaAuth.onChangePassword = this._onChangePassword.bind(this);
        symplaAuth.onError = this._handleResponseLogin.bind(this);
        symplaAuth.onLoad = this.onLoadAuthComponent.bind(this);
      }
    });
  }

  // react modal does not render the password component, so we do not have ref until modal is open
  initPasswordRef() {
    const symplaAuthChangePassword = this.symplaAuthChangePasswordRef.current

    if(symplaAuthChangePassword) {
      symplaAuthChangePassword.environment = REACT_APP_ENV || "development";
      symplaAuthChangePassword.onChangePassword = this._closeChangePassword.bind(this);
      symplaAuthChangePassword.recaptchaKey = REACT_APP_RECAPTCHA_KEY;
      symplaAuthChangePassword.onError = this._handleResponseLogin.bind(this);
    }
  }

  onLoadAuthComponent() {
    this.setState({ loadingAuthComponent: false });
  }

  initialCDKLoginWeb() {
    return new Promise<void>((resolve, reject) => {
      const timestamp = new Date().getTime()
      const script = document.createElement('script')
      // script.type = "module";
      // script.src = `http://local.cdklogin.sympla.com.br:5001/src/auth-component.ts`;
      script.src = `${REACT_APP_BILETO_MANAGER_CDKLOGIN_URL}/index.js?timestamp=${timestamp}`

      script.onload = () => {
        resolve()
      }

      script.onerror = (error) => {
        console.error('LOG ERR(cdk-login-web): ', error)
        reject(error);
      }

      document.head.appendChild(script)
    })
  }

  // componentWillUnmount() {
  //   this.emailRef.current?.removeEventListener('keypress', this.onKeypress);
  //   this.passwordRef.current?.removeEventListener('keypress', this.onKeypress);
  // }

  // onKeypress = (e: KeyboardEvent) => {
  //   if (e.key === 'Enter') {
  //     this.onLogin();
  //   }
  // };

  _handleResponseLogin(response: UserLoginResponse) {
    this.props.login(response);
  };

  _closeChangePassword() {
    this.setState({ openChangePassword: false })
  };

  _openChangePassword() {
    this.setState({ openChangePassword: true })
  }

  // onLogin = () => {
  //   const { loading } = this.props;
  //   if (!this.validateForm() || loading) {
  //     return;
  //   }
  //   this.setState({ errorMessage: null, loading: true });
  //   const username = this.emailRef.current?.value;
  //   const password = this.passwordRef.current?.value;

  //   this.props.login({
  //     username,
  //     password,
  //   });
  // };

  validateForm = () => {
    const validEmail = this.emailRef.current?.checkValidation();
    const validPassword = this.passwordRef.current?.checkValidation();

    return validEmail && validPassword;
  };

  render() {
    const { isLogged, errorMessage } = this.props;
    if (isLogged) {
      return <Redirect to={`${RoutesEnum.EVENT_MANAGER}organization/:orgId`} />;
    }
    return (
      <>
        <div className="login__content">
          <img src={Logo} alt="Logo da Sympla" className="login__logo" />

          <div className="login__wrapper">
            <p className="login__title">
              <Icon className="login__ticket" icon="ticket" />
              Organizador Bileto
            </p>
            <auth-component
              ref={this.symplaAuthRef}
              id="symplaAuth"
              app-name='organizer-app'
            ></auth-component>

            {errorMessage && <p className="login__error-message">{errorMessage}</p>}

            <button className="login__change-password" onClick={() => this._openChangePassword()}>Trocar de senha</button>
          </div>
          <ReactModal
            isOpen={this.state.openChangePassword} 
            style={customStyles}
            shouldCloseOnOverlayClick={true}
            shouldCloseOnEsc={true}
            onRequestClose={this._closeChangePassword}
            onAfterOpen={() => this.initPasswordRef()}
          > 
            <button className="login__change-password__close" onClick={() => this._closeChangePassword()}>X</button>
            <h3 className="login__change-password__title">Alterar senha</h3>
              <auth-component
                ref={this.symplaAuthChangePasswordRef}
                id="symplaAuthChangePassword"
                app-name='organizer-app'
                content-type="currentPassword"
              ></auth-component>
          </ReactModal>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ session: { isLogged, loading, error } }: StoreState) => ({
  isLogged,
  loading,
  errorMessage: error,
});

export default connect(mapStateToProps, { login })(Login);
