import { Dispatch } from 'react';
import { ActionTypes } from './actionTypes';
import { planApi } from '../api/plan';
import { Alert, SnackbarAlert } from '../model/general';
import { planMessages } from '../alertMessages';
import { PlanItem, PlanPage } from '../model/plan';
import { AxiosError } from 'axios';

export const addPlan = (payload: PlanItem) => ({
  type: ActionTypes.PLAN_ADD,
  payload,
});

export const addPlans = (payload: PlanPage) => ({
  type: ActionTypes.PLAN_PAGE_LIST,
  payload,
});

export const setPageListLoading = () => ({
  type: ActionTypes.PLAN_PAGE_LOADING,
});

export const setPlanLoading = () => ({
  type: ActionTypes.PLAN_LOADING,
});

export const setPageListAlert = (payload: Alert) => ({
  type: ActionTypes.PLAN_PAGE_ALERT,
  payload,
});

export const setPlanSaved = (payload: SnackbarAlert) => ({
  type: ActionTypes.PLAN_SAVED,
  payload,
});

export const setPlanSaving = () => ({
  type: ActionTypes.PLAN_SAVING,
});

export const resetPlanSaved = () => ({
  type: ActionTypes.RESET_PLAN_SAVED,
});

export const fetchPlans =
  (page = 0) =>
  async (dispatch: Dispatch<any>) => {
    const { notFound, error } = planMessages.page;
    try {
      dispatch(setPageListLoading());
      let { data } = await planApi.getPlans(page);
      dispatch(addPlans(data));
      data.empty && dispatch(setPageListAlert(notFound));
    } catch {
      dispatch(setPageListAlert(error));
    }
  };

export const fetchPlan = (exposedId: string) => async (dispatch: Dispatch<any>) => {
  try {
    dispatch(setPlanLoading());
    const { data } = await planApi.getPlan(exposedId);
    dispatch(addPlan(data));
  } catch {
    const { error } = planMessages.page;
    dispatch(setPageListAlert(error));
  }
};

export const createPlan = (plan: PlanItem) => async (dispatch: Dispatch<any>) => {
  try {
    dispatch(setPlanSaving());
    const { success } = planMessages.createPlan;
    const { data } = await planApi.createPlan(plan);
    dispatch(addPlan(data));
    dispatch(setPlanSaved(success as SnackbarAlert));
  } catch (e) {
    const { error } = planMessages.createPlan;
    handlePlanErrors(e as AxiosError, dispatch, error as SnackbarAlert);
  }
};

export const updatePlan = (id: string, plan: PlanItem) => async (dispatch: Dispatch<any>) => {
  try {
    dispatch(setPlanSaving());
    const { success } = planMessages.updatePlan;
    const { data } = await planApi.updatePlan(id, plan);
    dispatch(addPlan(data));
    dispatch(setPlanSaved(success as SnackbarAlert));
  } catch (e) {
    const { error } = planMessages.updatePlan;
    handlePlanErrors(e as AxiosError, dispatch, error as SnackbarAlert);
  }
};

const handlePlanErrors = (exception: AxiosError, dispatch: Dispatch<any>, defaultError: SnackbarAlert) => {
  const { message } =
    exception?.response?.data instanceof Array ? exception?.response?.data[0] : exception?.response?.data;
  if (message) {
    const error = { type: 'error', message };
    dispatch(setPlanSaved(error as SnackbarAlert));
  } else {
    dispatch(setPlanSaved(defaultError as SnackbarAlert));
  }
};
