import { Option } from '../MenuDropdown';

export const biletoOptions: Option[] = [
  {
    icon: 'ticket',
    name: 'Organizador Bileto',
    type: 'event',
    selected: true,
  },
  {
    icon: 'ticket-with-star',
    name: 'EM Defaults',
    type: 'link',
    link: 'https://eventmanager-defaults.bileto.sympla.com.br',
    props: {
      target: '_blank',
      rel: 'noopener noreferrer',
    },
  },
  {
    icon: 'chart-bar',
    name: 'Dashboard',
    type: 'link',
    link: 'https://produtor.bileto.sympla.com.br',
    props: {
      target: '_blank',
      rel: 'noopener noreferrer',
    },
  },
  {
    icon: 'map-marker',
    name: 'Scheme Manager',
    type: 'link',
    link: 'https://schememanager.bileto.sympla.com.br',
    props: {
      target: '_blank',
      rel: 'noopener noreferrer',
    },
  },
  {
    icon: 'dollar-circle',
    name: 'Portal Repasse',
    type: 'link',
    link: 'https://portal.bileto.sympla.com.br/',
    props: {
      target: '_blank',
      rel: 'noopener noreferrer',
    },
  },
  {
    icon: 'user',
    name: 'User Manager',
    type: 'link',
    link: 'https://usermanager.bileto.sympla.com.br/',
    props: {
      target: '_blank',
      rel: 'noopener noreferrer',
    },
  },
  {
    icon: 'store',
    name: 'Box Office',
    type: 'link',
    link: 'https://boxoffice.bileto.sympla.com.br/',
    props: {
      target: '_blank',
      rel: 'noopener noreferrer',
    },
  },
  {
    icon: 'truck',
    name: 'Logistics',
    type: 'link',
    link: 'https://logistics.bileto.sympla.com.br/',
    props: {
      target: '_blank',
      rel: 'noopener noreferrer',
    },
  },
  {
    icon: 'ticket-with-person',
    name: 'Customer Service',
    type: 'link',
    link: 'https://customerservice.bileto.sympla.com.br/',
    props: {
      target: '_blank',
      rel: 'noopener noreferrer',
    },
  },
];

export const organizerOptions = [
  {
    icon: 'calendar',
    name: 'Meus Eventos',
  },
  {
    icon: 'double-ticket',
    name: 'Pacotes',
  },
  {
    icon: 'sheet',
    name: 'Lista de Cupons',
  },
  {
    icon: 'credit-card',
    name: 'Lista de Bins',
  },
];

export const profileOptions = [
  {
    id: 'eventManager',
    icon: 'ticket',
    name: 'Gestão de evento',
    type: 'link',
    link:'/app'
  },
  { id:'logout', icon: 'arrow-left', name: 'Sair', type: 'event' },
];
