import React, { useEffect, useRef } from 'react';
import 'sympla-bootstrap/snackbar';

interface SnackbarProps {
  open?: boolean;
  duration?: number;
  type?: 'success' | 'error';
  message?: string;
  icon?: boolean;
  closeDisabled?: boolean;
  onClose?: () => void;
}

const Snackbar: React.FC<SnackbarProps> = ({ onClose, ...props }) => {
  const snackbarRef = useRef<HTMLElement>(null);
  const closeHandler = () => onClose && onClose();

  useEffect(() => {
    snackbarRef?.current?.addEventListener('close', closeHandler);
    return () => snackbarRef?.current?.removeEventListener('close', closeHandler);
  });

  return <sb-snackbar ref={snackbarRef} {...props}></sb-snackbar>;
};

Snackbar.defaultProps = {
  open: false,
  duration: 2,
  type: 'success',
  message: 'Alterações salvas com sucesso',
  icon: true,
  closeDisabled: false,
  onClose: () => {},
};

export default Snackbar;
