import React, { useEffect, useState } from 'react';
import './styles.scss';
import TextField from '../../../components/TextField';
import 'sympla-bootstrap/icon-button';
import Panel from '../../../components/Panel';
import { PlanScreenModeEnum } from '../../../enums/plan';
import { PlanItem } from '../../../model/plan';
import { currencyFormatterFromApi } from '../../../services/currencyService';

interface PlanCardProps {
  plan: PlanItem | null;
  screenMode?: PlanScreenModeEnum | null;
  editName: string;
  editValue: string;
  onNameChanged?: (name: string) => void;
  onValueChanged?: (value: string) => void;
  onValidate: (valid: boolean) => void;
}

const PlanCard = ({
  plan,
  screenMode,
  editName,
  editValue,
  onNameChanged,
  onValueChanged,
  onValidate,
}: PlanCardProps) => {
  const [invalidValue, setInvalidValue] = useState<boolean>(true);
  const [invalidName, setInvalidName] = useState<boolean>(true);

  useEffect(() => {
    onValidate(invalidValue && invalidName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invalidValue, invalidName]);

  return (
    <section className="plans-card">
      {screenMode === PlanScreenModeEnum.CREATE || screenMode === PlanScreenModeEnum.EDIT ? (
        <>
          <TextField
            key="planNameField"
            label="Nome do plano"
            placeholder="Plano"
            errorMessage="Nome inválido"
            required
            defaultValue={editName}
            onInput={onNameChanged}
            onValidate={setInvalidName}
          />
          <TextField
            key="planValueField"
            label="Valor"
            placeholder="R$"
            errorMessage="Valor inválido"
            required
            defaultValue={
              editValue === ''
                ? editValue
                : (Number(editValue) / 100).toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })
            }
            format="currency"
            onInput={value => {
              onValueChanged!(value);
            }}
            onValidate={setInvalidValue}
          />
        </>
      ) : (
        plan !== null && (
          <>
            <Panel
              key="planNamePanel"
              label="Nome do plano"
              value={plan.name}
              className="plans-card__panel"
            />
            <Panel
              key="planValuePanel"
              label="Valor"
              value={`R$ ${currencyFormatterFromApi(Number(plan.value))}`}
              className="plans-card__panel"
            />
          </>
        )
      )}
    </section>
  );
};

export default PlanCard;
