import React from 'react';
import { Alert } from '../../model/general';
import '../../styles/pages.scss';
import AlertMessage from '../AlertMessage';
import Paper from '../Paper';
import Snackbar from '../Snackbar';

interface SnackbarProps {
  showMessage: boolean;
  message: string | undefined;
  type: 'success' | 'error' | undefined;
  closeSnackbar: () => void;
}

interface PageListProps {
  title: string;
  topRightChildren?: React.ReactNode;
  searchArea?: React.ReactNode;
  children: React.ReactNode;
  table?: React.ReactNode;
  alert: Alert | null;
  loadData: () => void;
  snackbarProps: SnackbarProps;
}

const PageList = ({
  title,
  topRightChildren,
  searchArea,
  table,
  alert,
  loadData,
  children,
  snackbarProps,
}: PageListProps) => {
  const { message, closeSnackbar, showMessage, type } = snackbarProps;

  return (
    <div className="pages">
      <Paper elevation="2" className="pages__card">
        <div className="pages__card-header">
          <div className="pages__card-menu">
            <h3 className="pages__title">{title}</h3>
          </div>
          <div className="pages__button-group">{topRightChildren}</div>
        </div>
        {searchArea}
        {alert ? (
          <div className="pages__alert">
            <AlertMessage alert={alert} onClick={loadData} />
          </div>
        ) : (
          <>{table}</>
        )}
      </Paper>
      {children}
      <Snackbar
        open={showMessage}
        message={message}
        type={type}
        closeDisabled
        onClose={closeSnackbar}
      ></Snackbar>
    </div>
  );
};

export default PageList;
