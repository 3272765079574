import React from 'react';
import Tag from '../../../components/Tag';
import { currencyFormatter, isNullOrUndefined } from 'sympla-bootstrap/utils';
import './styles.scss';

interface OccupancyCardProps {
  className?: string;
  value?: number;
  label?: string;
  percentage?: number;
}

const OccupancyCard: React.FC<OccupancyCardProps> = ({ className, value, label, percentage }) => (
  !isNullOrUndefined(value) ? (
    <div className={className}>
      <div className="occupancy-card__data">
        <span className="occupancy-card__value">{value?.toLocaleString('pt-br')}</span>
        {!isNullOrUndefined(percentage) ? (
          <Tag className="occupancy-card__tag" appearance="brand" size="micro" styled="pale">
            {currencyFormatter(percentage ?? 0)}%
          </Tag>
        ) : null
        }
      </div>
      <span className="occupancy-card__label">{label}</span>
    </div>
  ) : null
);

OccupancyCard.defaultProps = {
  className: '',
};

export default OccupancyCard;
