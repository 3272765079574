import React from 'react';
import { Column } from 'react-table';
import Tag from '../../../components/Tag';
import {
  CardInstallStatusEnum,
  CardInstallStatusValue,
  PaymentFlowEnumValue,
  PurchaseStatusValue,
} from '../../../enums/subscription';
import { SelectOption } from '../../../model/general';
import { currencyFormatterFromApi } from '../../../services/currencyService';

export const seatsColumns: Column[] = [
  {
    Header: 'Nome da placa',
    accessor: 'label',
  },
  {
    Header: 'Assento',
    accessor: 'seat',
  },
  {
    Header: 'Setor',
    accessor: 'sector',
  },
  {
    Header: 'Código Mifare',
    accessor: 'cardCodeValue',
    Cell: ({ cell }) => cell.value ?? '---',
  },
  {
    Header: 'Instalação da placa',
    accessor: 'cardInstallStatus',
    Cell: ({ cell }) => {
      const { tag, value } = CardInstallStatusValue[cell.value];
      return (
        <Tag appearance={tag} styled="pale">
          {value}
        </Tag>
      );
    },
  },
  {
    Header: 'Status da cadeira',
    accessor: 'purchaseStatus',
    Cell: ({ cell }) => {
      const { tag, value } = PurchaseStatusValue[cell.value];
      return (
        <Tag appearance={tag} styled="pale">
          {value}
        </Tag>
      );
    },
  },
];

export const defaultPaymentColumns: any[] = [
  {
    Header: 'Método de pagamento',
    accessor: 'paymentMethodType',
  },
  {
    Header: 'Pagamento',
    accessor: 'installments',
    Cell: ({ cell: { value } }: {cell: any}) => (value === 1 ? 'A vista' : `${value}x`),
  },
  {
    Header: 'Valor Total',
    accessor: 'value',
    Cell: ({ cell: { value } }: {cell: any}) => `R$ ${currencyFormatterFromApi(value)}`,
  },
  {
    Header: 'Formato',
    accessor: 'paymentFlow',
    Cell: ({ cell }: {cell: any}) => {
      if (cell.value === null) return 'Pagamento único';
      const value = PaymentFlowEnumValue[cell.value];
      return value;
    },
  },
  {
    Header: 'Ciclo',
    accessor: 'cycle',
  },
];

export const chairInstallStatusOptions: SelectOption[] = [
  {
    key: CardInstallStatusEnum.INSTALLED,
    value: CardInstallStatusValue[CardInstallStatusEnum.INSTALLED].value,
  },
  {
    key: CardInstallStatusEnum.NOT_INSTALLED,
    value: CardInstallStatusValue[CardInstallStatusEnum.NOT_INSTALLED].value,
  },
];
