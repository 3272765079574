import { combineReducers, Reducer } from 'redux';
import { ActionTypes } from '../../actions/actionTypes';
import { User } from '../../model/session';

const isLogged: Reducer<boolean> = (state = false, { type }) => {
  switch (type) {
    case ActionTypes.USER_ADD:
      return true;
    case ActionTypes.LOGOUT:
      return false;
    default:
      return state;
  }
};

const user: Reducer<User | null> = (state = null, { type, payload }) => {
  switch (type) {
    case ActionTypes.USER_ADD:
      return payload;
    case ActionTypes.LOGOUT:
      return null;
    default:
      return state;
  }
};

const loading: Reducer<boolean> = (state = false, { type, payload }) => {
  switch (type) {
    case ActionTypes.LOGIN_LOADING:
      return payload;
    default:
      return state;
  }
};

const error: Reducer<string | null> = (state = null, { type, payload }) => {
  switch (type) {
    case ActionTypes.LOGIN_ERROR:
      return payload;
    case ActionTypes.USER_ADD:
      return null;
    default:
      return state;
  }
};

const checked: Reducer<boolean> = (state = false, { type }) => {
  switch (type) {
    case ActionTypes.USER_ADD:
    case ActionTypes.LOGIN_CHECKED:
      return true;
    default:
      return state;
  }
};

export default combineReducers({
  isLogged,
  user,
  loading,
  error,
  checked,
});
