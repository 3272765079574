import React from 'react';
import { classname } from 'sympla-bootstrap/utils';
import './styles.scss';

interface PanelProps {
  label: string;
  value?: string | number;
  component?: () => React.ReactNode;
  classSta?: string;
  className?: string;
}

const Panel: React.FC<PanelProps> = ({ label, value, component, classSta, className }) => (
  <div
    className={classname('panel', {
      [className || '']: className,
    })}
  >
    <div className="panel__label">{label}</div>
    <div className={`panel__text ${classSta}`}>{value || (component && component())}</div>
  </div>
);

export default Panel;
