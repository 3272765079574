import React from 'react';
import 'sympla-bootstrap/button';

interface ButtonProps {
  appearance?: string;
  styled?: string;
  size?: string;
  className?: string;
  type?: string;
  disabled?: boolean;
  icon?: string;
  loading?: boolean;
  children?: React.ReactNode;
  onClick?: (e: Event) => void;
}

const Button: React.FC<ButtonProps> = ({
  className,
  children,
  disabled,
  appearance,
  styled,
  size,
  type,
  icon,
  loading,
  onClick,
}) => {

  return (
    <sb-button
      class={className}
      appearance={appearance}
      styled={styled}
      type={type}
      size={size}
      disabled={disabled}
      icon={icon}
      loading={loading}
      onClick={onClick}
    >
      {children}
    </sb-button>
  )
};

Button.defaultProps = {
  appearance: 'brand',
  styled: 'solid',
  type: 'button',
  size: 'small',
  disabled: false,
};

export default Button;
