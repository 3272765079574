import React from 'react';
import { customStyles, navigationOptions, plansTableColumns } from './_data';
import DataTable from 'react-data-table-component';
import Spinner from '../../../components/Spinner';
import './styles.scss';
import { PlanItem, PlanPage } from '../../../model/plan';
import ExpandableDetails from './ExpandableDetails';

interface TableProps {
  plans: PlanPage | null;
  loading: boolean;
  className?: string;
  currentPage?: number;
  onRowClicked: (row: PlanItem, e?: MouseEvent) => void;
  onChangePage: (page: number, totalRows?: number) => void;
}
const PlansTable: React.FC<TableProps> = ({
  plans,
  loading,
  className,
  currentPage,
  onChangePage,
  onRowClicked,
}) => {
  return (
    <>
      <DataTable
        className={className}
        columns={plansTableColumns}
        data={plans?.content ?? []}
        paginationTotalRows={plans?.totalElements}
        paginationPerPage={15}
        onChangePage={onChangePage}
        onRowClicked={onRowClicked}
        paginationComponentOptions={navigationOptions}
        paginationRowsPerPageOptions={[15]}
        expandableRowsComponent={<ExpandableDetails onDetailsClick={onRowClicked} />}
        paginationDefaultPage={currentPage}
        expandableRows
        pagination
        paginationServer
        responsive
        highlightOnHover
        pointerOnHover
        noHeader
        paginationResetDefaultPage
        progressPending={loading}
        progressComponent={<Spinner loading className="pages__spinner" />}
        customStyles={customStyles}
      ></DataTable>
    </>
  );
};

export default PlansTable;
