import React from 'react';
import { toLocaleShortDate, toLocaleDate } from 'sympla-bootstrap/utils';
import Button from '../../../components/Button';
import Tag from '../../../components/Tag';
import {
  PaymentFlowEnum,
  PaymentMethodsEnum,
  PurchaseStatusEnum,
  SubscriptionScreenModeEnum,
  TransactionStatusEnum,
  TransactionStatusValue,
} from '../../../enums/subscription';
import { SubscriptionPayment } from '../../../model/subscription';
import { ignoredPaymentTypesArray, customStyles } from './_data';
import DataTable from 'react-data-table-component';
import Spinner from '../../../components/Spinner';
import './styles.scss';
import ExpandableDetails from './ExpandableDetails';
import CountdownTimer from '../../../components/CountdownTimer';
import { currencyFormatterFromApi } from '../../../services/currencyService';

interface TableProps {
  payments: SubscriptionPayment[] | null;
  screenMode?: SubscriptionScreenModeEnum | null;
  loading?: boolean;
  className?: string;
  currentPage?: number;
  installmentProcessing: number;
  purchaseStatus: PurchaseStatusEnum;
  onReceiveClick: (row: SubscriptionPayment) => void;
  onFinishProcessing: () => void;
}

const HistoryTable: React.FC<TableProps> = ({
  payments,
  screenMode,
  loading,
  className,
  installmentProcessing,
  purchaseStatus,
  onReceiveClick,
  onFinishProcessing,
}) => {
  const historyTableColumns = [
    {
      name: 'Cobrança',
      selector: 'installments',
      sortable: true,
      format: (row: SubscriptionPayment) => {
        if (row.paymentMethodType === PaymentMethodsEnum.FREE) return '-';
        const cellFormat =
          row.paymentFlow === PaymentFlowEnum.RECURRENCE
            ? `${row.installmentsCurrent}/${row.installments}`
            : row.installments === 1
            ? 'À vista'
            : `${row.installments}x`;

        return cellFormat;
      },
      maxWidth: '100px',
    },
    {
      name: 'Referência',
      selector: 'referenceDate',
      sortable: true,
      hide: 890,
      format: (row: SubscriptionPayment) => {
        if (row.paymentFlow !== PaymentFlowEnum.RECURRENCE || !row.referenceDate) return '-';
        return toLocaleShortDate(row.referenceDate);
      },
      maxWidth: '100px',
    },
    {
      name: 'Método de pagamento',
      selector: 'paymentMethodType',
      sortable: true,
      hide: 1088,
    },
    {
      name: 'Valor',
      selector: 'value',
      sortable: true,
      hide: 890,
      format: (row: SubscriptionPayment) => `R$ ${currencyFormatterFromApi(row.value)}`,
      maxWidth: '180px',
    },
    {
      name: 'Última atualização',
      selector: 'transactionDate',
      sortable: true,
      hide: 400,
      format: (row: SubscriptionPayment) => {
        if (row.transactionDate === null || ignoredPaymentTypesArray.indexOf(row.paymentMethodType) !== -1)
          return '-';
        return toLocaleDate(
          row.transactionDate,
          {
            day: '2-digit',
            month: '2-digit',
            year: '2-digit',
          },
          'pt-BR',
        );
      },
      maxWidth: '100px',
    },
    {
      name: 'Status',
      selector: 'transactionStatus',
      sortable: true,
      cell: (row: SubscriptionPayment) => {
        const { tag, value } = TransactionStatusValue[row.transactionStatus];
        return (
          <Tag appearance={tag} styled="pale">
            {value}
          </Tag>
        );
      },
      maxWidth: '100px',
    },
    {
      name: '',
      selector: 'transactionStatus',
      ignoreRowClick: true,
      button: true,
      sortable: false,
      hide: 890,
      cell: (row: SubscriptionPayment) => {
        return row.paymentFlow === PaymentFlowEnum.RECURRENCE &&
          purchaseStatus !== PurchaseStatusEnum.CANCELED &&
          row.transactionStatus === TransactionStatusEnum.OVERDUE ? (
          installmentProcessing === row.installmentsCurrent ? (
            <CountdownTimer timeInSeconds="30" onFinish={onFinishProcessing}></CountdownTimer>
          ) : (
            <Button
              styled="text"
              onClick={() => onReceiveClick(row)}
              disabled={screenMode !== SubscriptionScreenModeEnum.VIEW}
            >
              Receber
            </Button>
          )
        ) : (
          ''
        );
      },
      maxWidth: '130px',
    },
  ];

  return (
    <>
      <DataTable
        className={className}
        columns={historyTableColumns}
        data={payments ?? []}
        expandableRowsComponent={
          <ExpandableDetails
            screenMode={screenMode}
            installmentProcessing={installmentProcessing}
            purchaseStatus={purchaseStatus}
            onReceiveClick={onReceiveClick}
            onFinishProcessing={onFinishProcessing}
          />
        }
        expandableRows
        responsive
        highlightOnHover
        pointerOnHover
        noHeader
        progressPending={loading}
        progressComponent={<Spinner loading className="pages__spinner" />}
        customStyles={customStyles}
      ></DataTable>
    </>
  );
};

export default HistoryTable;
