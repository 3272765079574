const {
  REACT_APP_EVENT_MANAGER_API,
  REACT_APP_EM_EMBEDDED_URL,
  REACT_APP_USER_COOKIE_NAME,
  REACT_APP_USER_COOKIE_DOMAIN,
  REACT_APP_SUBSCRIPTION_API,
} = process.env;

export {
  REACT_APP_EVENT_MANAGER_API,
  REACT_APP_SUBSCRIPTION_API,
  REACT_APP_EM_EMBEDDED_URL,
  REACT_APP_USER_COOKIE_NAME,
  REACT_APP_USER_COOKIE_DOMAIN,
};
