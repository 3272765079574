import React from 'react';
import Header from '../Header';
import TabBar from '../TabBar';

interface LayoutProps {
  children: React.ReactNode;
  enableBiletoTools: boolean | undefined;
}

const Layout: React.FC<LayoutProps> = ({ children, enableBiletoTools }) => {
  return (
    <>
      <Header enableBiletoTools={enableBiletoTools} />
      <TabBar />
      {children}
    </>
  );
};

export default Layout;
