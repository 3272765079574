import { DynamicObject } from '../model/general';

export enum OverallItemCardInstallStatusEnum {
  PARTIAL = 'PARTIAL',
  NOT_INSTALLED = 'NOT_INSTALLED',
  INSTALLED = 'INSTALLED',
}

export enum TransactionStatusEnum {
  APPROVED = 'APPROVED',
  CANCELED = 'CANCELED',
  OVERDUE = 'OVERDUE',
}

export enum PurchaseStatusEnum {
  ACTIVE = 'ACTIVE',
  CANCELED = 'CANCELED',
  SUSPENDED = 'SUSPENDED',
  FINISHED = 'FINISHED',
}

export enum CardInstallStatusEnum {
  INSTALLED = 'INSTALLED',
  NOT_INSTALLED = 'NOT_INSTALLED',
}

export enum CardCodeVendorEnum {
  MIFARE = 'MIFARE',
}

export enum PaymentFlowEnum {
  INSTALLMENT = 'INSTALLMENT',
  RECURRENCE = 'RECURRENCE',
}

export enum PaymentMethodsEnum {
  BOLETO = 'Boleto',
  FREE = 'Gratuito',
  POS = 'Pos',
}

export enum SubscriptionScreenModeEnum {
  VIEW = 'view',
  EDIT_OWNER_DATA = 'data',
  EDIT_ADDRESS = 'address',
  EDIT_CHAIR = 'chair',
  PROCESSING_RECEIVE_INSTALLMENT = 'installment',
}

interface StatusTag {
  tag: string;
  value: string;
}

export const CardInstallStatusValue: DynamicObject<StatusTag> = {
  [CardInstallStatusEnum.INSTALLED]: {
    tag: 'brand',
    value: 'Instalada',
  },
  [CardInstallStatusEnum.NOT_INSTALLED]: {
    tag: 'neutral',
    value: 'Não instalada',
  },
};

export const TransactionStatusValue: DynamicObject<StatusTag> = {
  [TransactionStatusEnum.APPROVED]: {
    tag: 'analogue-primary',
    value: 'Aprovado',
  },
  [TransactionStatusEnum.CANCELED]: {
    tag: 'contrast-secondary',
    value: 'Cancelado',
  },
  [TransactionStatusEnum.OVERDUE]: {
    tag: 'contrast-primary',
    value: 'Em Atraso',
  },
};

export const PurchaseStatusValue: DynamicObject<StatusTag> = {
  [PurchaseStatusEnum.ACTIVE]: {
    tag: 'analogue-primary',
    value: 'Ativa',
  },
  [PurchaseStatusEnum.CANCELED]: {
    tag: 'contrast-secondary',
    value: 'Cancelada',
  },
  [PurchaseStatusEnum.SUSPENDED]: {
    tag: 'contrast-primary',
    value: 'Suspensa',
  },
  [PurchaseStatusEnum.FINISHED]: {
    tag: 'brand',
    value: 'Concluída',
  },
};

export const OverallItemCardInstallStatusValue: DynamicObject<StatusTag> = {
  [OverallItemCardInstallStatusEnum.PARTIAL]: {
    tag: 'neutral',
    value: 'Parcial',
  },
  [OverallItemCardInstallStatusEnum.NOT_INSTALLED]: {
    tag: 'neutral',
    value: 'Não instalada',
  },
  [OverallItemCardInstallStatusEnum.INSTALLED]: {
    tag: 'brand',
    value: 'Instalada',
  },
};

export const PaymentFlowEnumValue: DynamicObject<string> = {
  [PaymentFlowEnum.INSTALLMENT]: 'Parcelado',
  [PaymentFlowEnum.RECURRENCE]: 'Recorrente',
};

export const PaymentFlowMethodsValue: DynamicObject<string> = {
  [PaymentMethodsEnum.BOLETO]: 'Boleto',
  [PaymentMethodsEnum.FREE]: 'Gratuito',
  [PaymentMethodsEnum.POS]: 'Pos',
};

export const OWNER_DATA_NOT_EDITABLE_STATUS = [PurchaseStatusEnum.CANCELED, PurchaseStatusEnum.FINISHED];

export const CHAIR_NOT_EDITABLE_STATUS = [PurchaseStatusEnum.CANCELED];

export const CHAIR_DISABLE_EDIT_STATUS = [PurchaseStatusEnum.SUSPENDED];

export const PAYMENT_METHODS_SHOW_ONLY_HISTORY = [
  PaymentMethodsEnum.BOLETO,
  PaymentMethodsEnum.FREE,
  PaymentMethodsEnum.POS,
];
