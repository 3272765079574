import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Login from './Login';
import EventManager from './EventManager';
import Subscriptions from './Subscriptions';
import Plans from './Plans';
import { RoutesEnum } from '../enums/routes';

function Routes() {
  return (
    <BrowserRouter>
      <Route
        render={({ location }) => {
          return (
            <EventManager currentPath={location.pathname}>
              <Switch location={location}>
                <Route path={RoutesEnum.LOGIN} exact component={Login} />
                <Route path={RoutesEnum.SUBSCRIPTIONS} exact component={Subscriptions} />
                <Route path={RoutesEnum.PLANS} exact component={Plans} />
                <Route render={() => <Redirect to={RoutesEnum.EVENT_MANAGER} />} />
              </Switch>
            </EventManager>
          );
        }}
      ></Route>
    </BrowserRouter>
  );
}

export default Routes;
