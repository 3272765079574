import { Dispatch } from 'react';
import { REACT_APP_USER_COOKIE_DOMAIN, REACT_APP_USER_COOKIE_NAME } from '../constants';

import cookie from 'react-cookies';
// import { authApi } from '../api/auth';
import { Session, User, UserLoginResponse, UserPermission } from '../model/session';
import { DynamicObject } from '../model/general';
import { PermissionEnum } from '../enums/permission';
import { ActionTypes } from './actionTypes';

const featuresPermissions: DynamicObject<PermissionEnum[]> = {
  ACCESS_ORGANIZER: [
    PermissionEnum.INTERNAL_EVENT_MANAGER,
    PermissionEnum.EXTERNAL_EVENT_MANAGER,
    PermissionEnum.EVENT_MANAGER_SUPERVISOR,
  ],
  ACCESS_BILETO_TOOLS: [PermissionEnum.INTERNAL_EVENT_MANAGER],
  ACCESS_SUBSCRIPTIONS_PAGE: [PermissionEnum.INTERNAL_EVENT_MANAGER, PermissionEnum.EXTERNAL_EVENT_MANAGER],
};

const addLoginLoading = (payload = true) => ({
  type: ActionTypes.LOGIN_LOADING,
  payload,
});

const addUser = (payload: User) => ({
  type: ActionTypes.USER_ADD,
  payload,
});

const addLoginError = (payload: string) => ({
  type: ActionTypes.LOGIN_ERROR,
  payload,
});

const removeLogin = () => ({ type: ActionTypes.LOGOUT });

const addLoginChecked = () => ({ type: ActionTypes.LOGIN_CHECKED });

const openJwt = (jwt: string | null) => {
  const parties = jwt ? jwt.split('.') : [];
  const body = parties && parties.length === 3 ? atob(parties[1]) : undefined;
  return body ? JSON.parse(body) : undefined;
};

const userAllow = (userPermission: PermissionEnum[], featurePermissions: PermissionEnum[]) => {
  if (!userPermission) {
    return false;
  }

  return userPermission.some((p: PermissionEnum) => featurePermissions?.includes(p));
};

const getUserFeatures = (perms: PermissionEnum[]): DynamicObject<boolean> => {
  return {
    organizerEnabled: userAllow(perms, featuresPermissions.ACCESS_ORGANIZER),
    biletoToolsEnabled: userAllow(perms, featuresPermissions.ACCESS_BILETO_TOOLS),
    subscriptionPageEnabled: userAllow(perms, featuresPermissions.ACCESS_SUBSCRIPTIONS_PAGE),
  };
};

const getUserPermissions = (userPermission: PermissionEnum[]): UserPermission => {
  return {
    eventProducer: userAllow(userPermission, [PermissionEnum.EXTERNAL_EVENT_MANAGER]),
    eventSupervisor: userAllow(userPermission, [PermissionEnum.EVENT_MANAGER_SUPERVISOR]),
    eventManager: userAllow(userPermission, [PermissionEnum.INTERNAL_EVENT_MANAGER]),
  };
};

interface SaveSession {
  name: string;
  userfullname: string;
  token: string;
  perms: PermissionEnum[];
  exp: number;
}

const saveCookie = async ({ name, userfullname, token, perms, exp }: SaveSession) => {
  const session = { token, name, userfullname, perms: perms.join('.') };
  const expires = new Date(exp * 1000);

  cookie.save(REACT_APP_USER_COOKIE_NAME, JSON.stringify(session), {
    expires,
    domain: REACT_APP_USER_COOKIE_DOMAIN,
    path: '/',
  });
};

export const login = (response: UserLoginResponse) => async (dispatch: Dispatch<any>) => {
  if(response.status !== 200 && !response.data.token) return;

  try {
    //dispatch(addLoginLoading());
    //const { headers } = await authApi.login(user);
    const token = response.data.token;
    const authDetails = openJwt(token);
    const { name, userfullname } = JSON.parse(authDetails.ud);
    const perms = JSON.parse(authDetails.perms)
      .map((perm: { role: PermissionEnum }) => perm.role)
      .filter((perm: PermissionEnum) => featuresPermissions.ACCESS_ORGANIZER.includes(perm));

      const features = getUserFeatures(perms);

    if (!features.organizerEnabled) {
      dispatch(addLoginError('Você não possui a permissão necessária para acessar este sistema!'));
      return;
    }

    const permissions = getUserPermissions(perms);

    saveCookie({ name, userfullname, token: String(token), perms, exp: authDetails.exp });
    dispatch(addUser({ name: userfullname, features, permissions }));
  } catch (error: any) {
    const { status = 500 } = error?.response || {};
    const errorMessage =
      status === 401
        ? 'Usuário e/ou senha inválidos. Por favor, tente novamente.'
        : 'Falha ao tentar autenticar. Por favor, verifique sua conexão e tente novamente';

    dispatch(addLoginError(errorMessage));
  } finally {
    dispatch(addLoginLoading(false));
  }
};

export const checkUser = () => (dispatch: Dispatch<any>) => {
  const session: Session = cookie.load(REACT_APP_USER_COOKIE_NAME);
  if (session && session.token) {
    const { userfullname, perms } = session;
    const permsList = perms.split('.') as PermissionEnum[];
    const features = getUserFeatures(permsList);
    const permissions = getUserPermissions(permsList);
    dispatch(addUser({ name: userfullname, features, permissions }));
    return;
  }
  dispatch(addLoginChecked());
};

export const logout = () => (dispatch: Dispatch<any>) => {
  cookie.remove(REACT_APP_USER_COOKIE_NAME, {
    domain: REACT_APP_USER_COOKIE_DOMAIN,
    path: '/',
  });

  dispatch(removeLogin());
};
