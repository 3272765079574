import { SubscriptionPayment } from './model/subscription';
import { toLocaleShortDate } from 'sympla-bootstrap/utils';
import { currencyFormatterFromApi } from './services/currencyService';

export const subscriptionConfirmMessages = {
  installment: {
    message: (row: SubscriptionPayment) =>
      `Será feita uma cobrança no valor de <b>R$ ${currencyFormatterFromApi(
        row.value,
      )}</b> da mensalidade de <b>${toLocaleShortDate(
        row.referenceDate,
      )}</b> no cartão atual cadastrado pelo assinante.`,
  },
  renew: {
    message: `A assinatura <b>não será renovada</b> automaticamente após o término da vigência e o usuário perderá o acesso aos seus benefícios após esse período`,
  },
};
