import React from 'react';
import { Alert } from '../../model/general';
import Spinner from '../Spinner';
import './styles.scss';

interface AlertMessage {
  alert: Alert;
  onClick?: (action: string | undefined) => void;
}

const AlertMessage: React.FC<AlertMessage> = ({ alert, onClick }) => {
  const getIconDialog = (type = '') => {
    switch (type) {
      case 'success':
        return 'check-circle';
      case 'warning':
        return 'exclamation-triangle';
      case 'error':
        return 'times-circle';
    }
  };

  const { type, title, message, buttonAction } = alert;

  return (
    <div className="alert-message">
      <div className="alert-message__header">
        {type &&
          (type === 'loading' ? (
            <Spinner loading />
          ) : (
            <sb-icon
              class={`alert-message__icon alert-message--${type}`}
              icon={getIconDialog(type)}
            ></sb-icon>
          ))}
      </div>
      <h4 className="alert-message__title">{title}</h4>
      <p className="alert-message__message" dangerouslySetInnerHTML={{ __html: message ?? '' }}></p>
      {buttonAction && (
        <sb-button class="alert-message__button" onClick={() => onClick && onClick(buttonAction?.action)}>
          {buttonAction.text}
        </sb-button>
      )}
    </div>
  );
};

export default AlertMessage;
