import { combineReducers, Reducer } from 'redux';
import { ActionTypes } from '../../actions/actionTypes';
import { ORGANIZATION_ADD, ORGANIZATION_SELECT } from '../../actions/organizations';
import { Organization } from '../../model/organization';

const items: Reducer<Organization[]> = (state = [], { type, payload }) => {
  switch (type) {
    case ORGANIZATION_ADD:
      return payload;
    case ActionTypes.LOGOUT:
      return [];
    default:
      return state;
  }
};

const selected: Reducer<Organization | null> = (state = null, { type, payload }) => {
  switch (type) {
    case ORGANIZATION_SELECT:
      return payload;
    case ActionTypes.LOGOUT:
      return null;
    default:
      return state;
  }
};

export default combineReducers({
  items,
  selected,
});
