import { PlanItem, PlanPage } from '../model/plan';
import { subscriptionGateway } from './apiGateway';

export const planApi = {
  getPlans: (page: number) => subscriptionGateway.get<PlanPage>(`/api/v1/plans?size=15&page=${page}`),
  getPlan: (exposedId: string) => subscriptionGateway.get<PlanItem>(`/api/v1/plans/${exposedId}`),
  createPlan: (plan: PlanItem) =>
    subscriptionGateway.post<PlanItem>(`/api/v1/plans`, {
      name: plan.name,
      value: plan.value,
      statement_descriptor: 'PassaporteAllianz',
      sectors: [],
    }),
  updatePlan: (exposedId: string, plan: PlanItem) =>
    subscriptionGateway.patch<PlanItem>(`/api/v1/plans/${exposedId}`, {
      name: plan.name,
      value: plan.value,
      sectors: plan.sectors,
    }),
};
