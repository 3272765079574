import React, { useEffect, useRef } from 'react';
import 'sympla-bootstrap/file-field';

interface FileFieldProps {
  label: string;
  accept?: string;
  multiple?: string;
  appearance?: string;
  styled?: string;
  className?: string;
  resetValue?: boolean;

  onChange?: (files: FileList | null) => void;
}

const FileField: React.FC<FileFieldProps> = ({
  label,
  accept,
  multiple,
  appearance,
  styled,
  className,
  resetValue,
  onChange,
}) => {
  const ref = useRef<HTMLInputElement>(null);
  const changeHandler = () => {
    onChange && onChange(ref.current?.files ?? null);

    if (resetValue && ref.current) {
      ref.current.value = '';
    }
  };

  useEffect(() => {
    ref?.current?.addEventListener('change', changeHandler);
    return () => ref?.current?.removeEventListener('change', changeHandler);
  });

  return (
    <sb-file-field
      class={className}
      ref={ref}
      label={label}
      accept={accept}
      multiple={multiple}
      appearance={appearance}
      styled={styled}
    ></sb-file-field>
  );
};

FileField.defaultProps = {
  resetValue: true,
  onChange: () => {},
};

export default FileField;
