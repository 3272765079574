import React from 'react';
import './styles.scss';
import { classname } from 'sympla-bootstrap/utils';

interface CardSelectionProps {
  text: string;
  selected?: boolean;
  onClick?: () => void;
}

const CardSelection: React.FC<CardSelectionProps> = ({ text, selected, onClick }: CardSelectionProps) => {
  return (
    <div
      className={classname('card-selection', {
        'card-selection--selected': selected,
      })}
      onClick={() => onClick && onClick()}
    >
      {text}
    </div>
  );
};

CardSelection.defaultProps = {
  selected: false,
  onClick: () => {},
};

export default CardSelection;
