import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import 'sympla-bootstrap/menu-dropdown';
import { classname } from 'sympla-bootstrap/utils';
import { PermissionEnum } from '../../enums/permission';
import { DynamicObject } from '../../model/general';
import { userAllow } from '../../services/authService';
import './styles.scss';

export interface Option {
  id?: string;
  icon?: string;
  name?: string;
  type?: string;
  link?: string;
  selected?: boolean;
  permissions?: PermissionEnum[];
  props?: DynamicObject<string>;
}

interface MenuDropdownProps {
  options?: Option[];
  className?: string;
  children?: any;
  optionsTitle?: string;
  listClassName?: string;
  optionsTitleClassName?: string;
  optionsClassNames?: string;
  disabled?: boolean;
  onClick?: () => void;
  onItemClick?: (item: Option) => void;
}

interface MenuItemProps {
  className?: string;
  selected?: boolean;
  icon?: string;
  name?: string;
  onClick?: () => void;
}

const MenuItem: React.FC<MenuItemProps> = ({ className, selected, icon, name, onClick }: MenuItemProps) => (
  <sb-list-item
    class={classname(className ?? '', {
      'menu-item--selected': selected,
    })}
    icon={icon}
    onClick={onClick}
  >
    <span>{name}</span>
  </sb-list-item>
);

MenuItem.defaultProps = {
  className: '',
  selected: false,
  icon: '',
  name: '',
  onClick: () => {},
};

const MenuDropdown: React.FC<MenuDropdownProps> = ({
  options,
  children,
  className,
  listClassName,
  optionsTitle,
  optionsTitleClassName,
  optionsClassNames,
  disabled,
  onClick,
  onItemClick,
}: MenuDropdownProps) => {
  const menuDropdownRef = useRef<HTMLElement>(null);
  const clickHandler = () => onClick && onClick();

  useEffect(() => {
    menuDropdownRef?.current?.addEventListener('click', clickHandler);
    return () => menuDropdownRef?.current?.removeEventListener('click', clickHandler);
  });

  return (
    <sb-menu-dropdown ref={menuDropdownRef} class={className} disabled={disabled}>
      {children}
      {options && Boolean(options.length) && (
        <sb-list slot="menu-dropdown-list" className={listClassName} class={listClassName}>
          {optionsTitle && <h3 className={optionsTitleClassName}>{optionsTitle}</h3>}
          {options?.map(({ props, ...option }, key) => {
            if (option.permissions && !userAllow(option.permissions)) {
              return;
            }
            if (option.type === 'link') {
              return (
                <Link
                  key={key}
                  className="menu-dropdown__link"
                  to={{
                    pathname: option.link,
                  }}
                  target={props?.target}
                  rel={props?.rel}
                >
                  <MenuItem
                    className={optionsClassNames}
                    selected={option.selected}
                    icon={option.icon}
                    name={option.name}
                  />
                </Link>
              );
            }
            return (
              <MenuItem
                key={key}
                className={optionsClassNames}
                selected={option.selected}
                icon={option.icon}
                name={option.name}
                onClick={() => onItemClick && onItemClick(option)}
              />
            );
          })}
        </sb-list>
      )}
    </sb-menu-dropdown>
  );
};

MenuDropdown.defaultProps = {
  options: [],
  className: '',
  children: null,
  optionsTitle: '',
  listClassName: '',
  optionsTitleClassName: '',
  optionsClassNames: '',
  disabled: false,
  onClick: () => {},
  onItemClick: () => {},
};

export default MenuDropdown;
