import React, { PureComponent } from 'react';
import MenuDropdown, { Option } from '../MenuDropdown';
import { biletoOptions, profileOptions } from './_data';
import './styles.scss';
import Dialog, { DialogFooter } from '../Dialog';
import Organizations from '../Organizations';
import { connect } from 'react-redux';
import { selectOrganization } from '../../actions/organizations';
import { Organization } from '../../model/organization';
import { StoreState } from '../../model/store';
import { logout } from '../../actions/session';
import 'sympla-bootstrap/header';

interface HeaderProps {
  organization: Organization;
  organizations: Organization[];
  enableBiletoTools: boolean | undefined;
  userName: string;
  selectOrganization: (organization: Organization) => void;
  logout: () => void;
}

interface HeaderState {
  organizationDialogOpen: boolean;
  organizationSelected: Organization | null;
}

class Header extends PureComponent<HeaderProps, HeaderState> {
  public readonly state = {
    organizationDialogOpen: false,
    organizationSelected: null,
  };

  logout = () => {
    this.props.logout();
  };

  onItemClicked = (item: Option) => {
    switch (item.id) {
      case 'logout':
        this.logout();
        break;
    }
  };

  openOrganizationDialog = () => this.setState({ organizationDialogOpen: true });

  closeOrganizationDialog = () => this.setState({ organizationDialogOpen: false });

  confirmOrganization = () => {
    const { organizationSelected } = this.state;
    const org = organizationSelected ?? this.props.organization;
    this.props.selectOrganization(org);
    this.closeOrganizationDialog();
  };

  onOrganizationSelect = (organizationSelected: Organization) => {
    this.setState({ organizationSelected });
  };

  renderOrganizationDropdown() {
    const { organization } = this.props;
    return (
      <div className="header__organization">
        <MenuDropdown className="header-menu__org-selector" onClick={this.openOrganizationDialog}>
          <sb-icon icon="store" class="header-menu__icon" />
          <div className="header-menu__org-selector__line-clamp">{organization?.name}</div>
        </MenuDropdown>
      </div>
    );
  }

  render() {
    const { organizationDialogOpen } = this.state;
    const { enableBiletoTools, userName } = this.props;

    return (
      <>
        <sb-header class="header">
          <div slot="header-left">
            <MenuDropdown
              className="header--desktop-show"
              options={biletoOptions}
              disabled={!enableBiletoTools}
              listClassName="header-menu__list header-menu--float-down-left"
              optionsTitle="Ferramentas"
              optionsTitleClassName="header-menu__title"
              optionsClassNames="header-menu__item"
            >
              <sb-icon class="header-menu__icon" icon="ticket" />
              <span>Organizador Bileto</span>
            </MenuDropdown>
          </div>
          <div className="header__slot-right" slot="header-right">
            {this.renderOrganizationDropdown()}
            <MenuDropdown
              className="menu-dropdown__user-dropdown"
              options={profileOptions}
              onItemClick={this.onItemClicked}
              listClassName="header-menu__list header-menu--float-down-right"
              optionsClassNames="header-menu__item"
            >
              <sb-avatar name={userName} />
            </MenuDropdown>
          </div>
        </sb-header>
        <Dialog
          open={organizationDialogOpen}
          title="Selecione uma empresa"
          fullscreen
          closeOutsideClick
          closeEscPress
          onClose={this.closeOrganizationDialog}
        >
          <Organizations onSelect={this.onOrganizationSelect} />
          <DialogFooter>
            <sb-button onClick={this.confirmOrganization}>Confirmar</sb-button>
          </DialogFooter>
        </Dialog>
      </>
    );
  }
}

const mapStateToProps = ({ organizations, session: { user } }: StoreState) => ({
  organizations: organizations.items,
  organization: organizations.selected,
  userName: user?.name || '',
});

export default connect(mapStateToProps, {
  selectOrganization,
  logout,
  // @ts-ignore
})(Header);
