import React, { useRef } from 'react';
import 'sympla-bootstrap/select-field';

interface SelectFieldProps {
  className?: string;
  label?: string;
  name?: string;
  placeholder?: string;
  required?: boolean;
  errorMessage?: string;
  defaultValue?: string;
  disabled?: boolean;
  noRequiredMark?: boolean;
  noEmptyOption?: boolean;
  autocomplete?: string;
  readonly?: boolean;
  invalid?: boolean;
  children?: React.ReactNode;
  onChange?: (value: string) => void;
}

const SelectField: React.FC<SelectFieldProps> = ({
  className,
  label,
  name,
  placeholder,
  required,
  errorMessage,
  defaultValue,
  disabled,
  noRequiredMark,
  noEmptyOption,
  autocomplete,
  readonly,
  invalid,
  children,
  onChange,
}) => {
  const inputRef = useRef<SbSelectFieldElement>(null);

  const handlerInput = (event: Event) => {
    inputRef && onChange && onChange(inputRef.current?.value || '');
  };

  return (
    <sb-select-field
      class={className}
      ref={inputRef}
      label={label}
      name={name}
      defaultvalue={defaultValue}
      placeholder={placeholder}
      required={required}
      errorMessage={errorMessage}
      disabled={disabled}
      noRequiredMark={noRequiredMark}
      noEmptyOption={noEmptyOption}
      autocomplete={autocomplete}
      readonly={readonly}
      invalid={invalid}
      onInput={handlerInput}
    >
      {children}
    </sb-select-field>
  );
};

export default SelectField;
