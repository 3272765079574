import React from 'react';
import './styles.scss';
import Checkmark from '../../assets/check-mark.svg';

interface InputCheckboxProps {
  name: string;
  isChecked: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  index: number;
}

const InputCheckbox: React.FC<InputCheckboxProps> = ({ name, isChecked, onChange, index }) => {
  return (
    <label className="checkbox__label">
      <div className="checkbox__input-container">
        <input
          type="checkbox"
          name={name}
          value={name}
          checked={isChecked}
          onChange={onChange}
          id={`custom-checkbox-${index}`}
          className="checkbox__input--hidden"
        />
        <div className={`checkbox__input--styled ${isChecked && 'checked'}`}>
          <img src={Checkmark} alt="checkmark" className="checkbox__input-icon" />
        </div>
      </div>
      <span className="checkbox__span">{name}</span>
    </label>
  );
};

export default InputCheckbox;
