export enum ActionTypes {
  SUBSCRIPTION_DIALOG = 'SUBSCRIPTION_DIALOG',
  SUBSCRIPTION_CLEAN_DIALOG = 'SUBSCRIPTION_CLEAN_DIALOG',
  SUBSCRIPTION_PAGE_LOADING = 'SUBSCRIPTION_PAGE_LOADING',
  SUBSCRIPTION_PAGE_ALERT = 'SUBSCRIPTION_PAGE_ALERT',
  SUBSCRIPTION_PAGE_LIST = 'SUBSCRIPTION_PAGE_LIST',
  SUBSCRIPTION_ADD = 'SUBSCRIPTION_ADD',
  SUBSCRIPTION_LOADING = 'SUBSCRIPTION_LOADING',
  SUBSCRIPTION_ERROR = 'SUBSCRIPTION_ERROR',
  SUBSCRIPTION_OCCUPANCY = 'SUBSCRIPTION_OCCUPANCY',
  SUBSCRIPTION_SAVING = 'SUBSCRIPTION_SAVING',
  SUBSCRIPTION_SAVED = 'SUBSCRIPTION_SAVED',
  RESET_SUBSCRIPTION_SAVED = 'RESET_SUBSCRIPTION_SAVED',

  PLAN_ADD = 'PLAN_ADD',
  PLAN_ERROR = 'PLAN_PAGE_ERROR',
  PLAN_LOADING = 'PLAN_LOADING',
  PLAN_PAGE_LIST = 'PLAN_PAGE_LIST',
  PLAN_PAGE_LOADING = 'PLAN_PAGE_LOADING',
  PLAN_PAGE_ALERT = 'PLAN_PAGE_ALERT',
  PLAN_SAVED = 'PLAN_SAVED',
  PLAN_SAVING = 'PLAN_SAVING',
  RESET_PLAN_SAVED = 'RESET_PLAN_SAVED',

  LOGIN_LOADING = 'LOGIN_LOADING',
  LOGOUT = 'LOGOUT',
  LOGIN_ERROR = 'LOGIN_ERROR',
  LOGIN_CHECKED = 'LOGIN_CHECKED',
  USER_ADD = 'USER_ADD',
}
