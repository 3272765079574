import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Organization } from '../../model/organization';
import { StoreState } from '../../model/store';
import CardSelection from '../CardSelection';

interface OrganizationsProps {
  organizations?: Organization[];
  initialOrganization?: Organization | null;
  onSelect: (organization: Organization) => void;
}

const Organizations: React.FC<OrganizationsProps> = ({
  organizations,
  initialOrganization,
  onSelect,
}: OrganizationsProps) => {
  const [selected, setSelected] = useState<Organization | null>(null);

  useEffect(() => {
    initialOrganization && setSelected(initialOrganization);
  }, [initialOrganization]);

  return (
    <div>
      {organizations?.map(organization => (
        <CardSelection
          key={organization.id}
          text={organization.name}
          selected={selected?.id === organization.id}
          onClick={() => {
            setSelected(organization);
            onSelect(organization);
          }}
        />
      ))}
    </div>
  );
};

const mapStateToProps = ({ organizations }: StoreState) => ({
  organizations: organizations.items,
  initialOrganization: organizations.selected,
});

export default connect(mapStateToProps, null)(Organizations);
