import React from 'react';
import 'sympla-bootstrap/icon';

interface IconProps {
  icon: string;
  className?: string;
  slot?: string;
  onClick?: () => void;
}

const Icon: React.FC<IconProps> = ({ className, icon, slot, onClick }: IconProps) => (
  <sb-icon class={className} icon={icon} slot={slot} onClick={onClick}></sb-icon>
);

export default Icon;
