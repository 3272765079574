import React, { useCallback, useEffect, useRef, useState } from 'react';
import { History } from 'history';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import Paper from '../../components/Paper';
import '../../styles/pages.scss';
import Dialog, { DialogFooter, DialogHeaderComplement } from '../../components/Dialog';
import { StoreState } from '../../model/store';
import {
  cleanDialogSubscription,
  exportSeats,
  exportSubscriptions,
  fetchSubscription,
  resetSubscriptionSaved,
  uploadSubscriptions,
  updateSubscription,
  updateSubscriptionChair,
  updateSubscriptionAddress,
  updateSubscriptionRenew,
} from '../../actions/subscription';
import { fetchSubscriptions, fetchOccupancy, receiveInstallment } from '../../actions/subscription';
import FileField from '../../components/FileField';
import { RoutesEnum } from '../../enums/routes';
import { Alert, SnackbarAlert, DynamicObject, RoutesParams } from '../../model/general';
import AlertMessage from '../../components/AlertMessage';
import SubscriptionCard from './SubscriptionCard';
import {
  Address,
  Occupancy,
  SeatItem,
  Subscription,
  SubscriptionFilter,
  SubscriptionItem,
  SubscriptionPage,
  SubscriptionPayment,
} from '../../model/subscription';
import SubscriptionsTable from './SubscriptionsTable';
import OccupancyCard from './OccupancyCard';
import 'sympla-bootstrap/icon-button';
import { classname, phoneFormatter } from 'sympla-bootstrap/utils';
import { fetchOrganizations } from '../../actions/organizations';
import {
  SubscriptionScreenModeEnum,
  OWNER_DATA_NOT_EDITABLE_STATUS,
  CardInstallStatusEnum,
} from '../../enums/subscription';
import Snackbar from '../../components/Snackbar';
import ConfirmBox from '../../components/ConfirmBox';
import Button from '../../components/Button';
import SubscriptionsFilterBox from '../../components/SubscriptionsFilterBox';
import { subscriptionConfirmMessages } from '../../confirmMessages';
import Layout from '../../components/Layout';

interface SubscriptionsProps {
  organizationIdSelected?: number;
  dialog?: Alert | null;
  history: History;
  features?: DynamicObject<boolean>;
  subscription: Subscription | null;
  subscriptionLoading: boolean;
  subscriptionError: Alert | null;
  subscriptionSaving: boolean;
  subscriptionSaved: SnackbarAlert | null;
  subscriptions: SubscriptionPage | null;
  pageLoading: boolean;
  pageAlert: Alert | null;
  occupancy: Occupancy | null;
  cleanDialogSubscription: () => void;
  exportSeats: (eventId: string) => void;
  exportSubscriptions: (eventId: string) => void;
  uploadSubscriptions: (file: File, eventId: string) => void;
  fetchSubscription: (id: string) => void;
  fetchOccupancy: (eventId: string) => void;
  fetchSubscriptions: (eventId: string, currentPage: number, filter?: SubscriptionFilter) => void;
  updateSubscription: (id: string, subscription: Subscription) => void;
  resetSubscriptionSaved: () => void;
}

const SubscriptionsPage: React.FC<SubscriptionsProps> = ({
  history,
  organizationIdSelected,
  fetchSubscriptions,
  fetchOccupancy,
  resetSubscriptionSaved,
  dialog,
  features,
  subscriptions,
  pageLoading,
  pageAlert,
  subscription,
  subscriptionLoading,
  subscriptionError,
  subscriptionSaving,
  subscriptionSaved,
  occupancy,
}) => {
  const [fileUpload, setFileUpload] = useState<File | null>(null);
  const [subscriptionSelected, setSubscriptionSelected] = useState<SubscriptionItem | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [buttonGroupOpened, setButtonGroupOpened] = useState(false);
  const [screenMode, setScreenMode] = useState<SubscriptionScreenModeEnum | null>(null);
  const [isChanged, setIsChanged] = useState(false);
  const [showDiscardChanges, setShowDiscardChanges] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [isReloadNecessary, setIsReloadNecessary] = useState(true);
  const [showMessage, setShowMessage] = useState(false);
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [enableSaveButton, setEnableSaveButton] = useState(true);
  const [currentChairEdit, setCurrentChairEdit] = useState<SeatItem | null>(null);
  const [currentAddressEdit, setCurrentAddressEdit] = useState<Address | null>(null);
  const [chairIndex, setChairIndex] = useState<number>(-1);
  const [showConfirmReceiveInstallment, setShowConfirmReceiveInstallment] = useState<boolean>(false);
  const [showConfirmDeactivateRenew, setShowConfirmDeactivateRenew] = useState<boolean>(false);
  const [paymentInstallment, setPaymentInstallment] = useState<number>(0);
  const [installmentMessage, setInstallmentMessage] = useState<string>('');
  const [filterSelected, setFilterSelected] = useState<SubscriptionFilter>();
  const mounted = useRef(false);
  const { orgId, eventId }: RoutesParams = useParams();
  const dispatch = useDispatch();

  const changeRoute = useCallback((route: string) => history.push(route), [history]);

  const loadSubscriptions = useCallback(
    (page = 1) => {
      setCurrentPage(page);
      fetchSubscriptions(eventId, page - 1, filterSelected);
    },
    [eventId, fetchSubscriptions, filterSelected],
  );

  const loadOccupancyData = useCallback(() => {
    fetchOccupancy(eventId);
  }, [eventId, fetchOccupancy]);

  const uploadFile = useCallback(() => {
    if (fileUpload && eventId) {
      dispatch(uploadSubscriptions(fileUpload, eventId));
    }
  }, [fileUpload, eventId, dispatch]);

  const loadSubscription = useCallback(() => {
    dispatch(fetchSubscription(subscriptionSelected!.id));
  }, [subscriptionSelected, dispatch]);

  const exportFile = (type: string) => {
    if (eventId) {
      switch (type) {
        case 'seats':
          dispatch(exportSeats(eventId));
          break;
        case 'subscriptions':
          dispatch(exportSubscriptions(eventId));
          break;
      }
    }
  };

  const dialogClick = (action = '') => {
    switch (action) {
      case 'close':
        closeDialog();
        break;
      case 'retry_seats_export':
        if (eventId) {
          exportSeats(eventId);
        }
        break;
      case 'retry_subscriptions_export':
        if (eventId) {
          exportSubscriptions(eventId);
        }
        break;
      case 'retry_subscriptions_import':
        uploadFile();
        break;
    }
  };

  const onFileLoad = (files: FileList | null) => {
    if (!files) {
      return;
    }

    setFileUpload(files[0]);
  };

  const closeDialog = () => dispatch(cleanDialogSubscription());

  const openDetails = (subscriptionSelected: SubscriptionItem) => {
    setSubscriptionSelected(subscriptionSelected);
  };

  const closeSubscriptionDetails = () => {
    if (isChanged) {
      setShowDiscardChanges(true);
    } else {
      setSubscriptionSelected(null);
      setPaymentInstallment(0);
    }
  };

  const changeSubscriptionPage = (currentPage: number) => {
    loadSubscriptions(currentPage);
  };

  const filterSubscriptions = (
    searchValue: string = '',
    filterStatusSelected: string[] = [],
    endNextDays: string = '',
    chairStatus: CardInstallStatusEnum | '' = '',
  ) => {
    setFilterSelected({
      searchValue: searchValue,
      purchaseStatus: filterStatusSelected,
      endNextDays: endNextDays,
      chairStatus: chairStatus,
    });
  };

  const resetFilter = () => {
    setFilterSelected(undefined);
  };

  const changeButtonGroup = () => {
    setButtonGroupOpened(!buttonGroupOpened);
  };

  const saveSubscription = async (subscription: Subscription | null) => {
    if (subscription === null) return;

    if (screenMode === SubscriptionScreenModeEnum.EDIT_OWNER_DATA) {
      subscription.subscriberPhone = phone;
      subscription.subscriberEmail = email;
      dispatch(updateSubscription(subscription.id, subscription));
    } else if (screenMode === SubscriptionScreenModeEnum.EDIT_CHAIR) {
      if (currentChairEdit === null) return;
      dispatch(updateSubscriptionChair(subscription.id, currentChairEdit));
    } else if (screenMode === SubscriptionScreenModeEnum.EDIT_ADDRESS) {
      if (currentAddressEdit === null) return;
      dispatch(updateSubscriptionAddress(subscription.id, currentAddressEdit));
    }
  };

  const handleEditClick = (e: Event) => {
    setScreenMode(SubscriptionScreenModeEnum.EDIT_OWNER_DATA);
    setEmail(subscription?.subscriberEmail || '');
    setPhone(phoneFormatter(subscription?.subscriberPhone || ''));
  };

  const onEmailChanged = (email: string) => {
    setIsChanged(email !== subscription?.subscriberEmail);
    setEmail(email);
  };

  const onPhoneChanged = (phone: string) => {
    setIsChanged(phoneFormatter(phone) !== subscription?.subscriberPhone);
    setPhone(phone);
  };

  const onCancelDiscard = () => {
    setShowDiscardChanges(false);
  };

  const onConfirmDiscard = () => {
    setIsChanged(false);
    setShowDiscardChanges(false);
    setCurrentChairEdit(null);
    setCurrentAddressEdit(null);
    setChairIndex(-1);
    setScreenMode(SubscriptionScreenModeEnum.VIEW);
  };

  const onValidate = (valid: boolean) => setEnableSaveButton(valid);

  const onValidateAddress = (address: any) => setEnableSaveButton(!Object.values(address).includes(false));

  const closeSnackbar = () => {
    setShowMessage(false);
    dispatch(resetSubscriptionSaved());
  };

  const onEditChair = (chair: SeatItem) => {
    setScreenMode(SubscriptionScreenModeEnum.EDIT_CHAIR);
    setCurrentChairEdit({ ...chair });
    setChairIndex(
      subscription
        ? subscription.subscriptionItems.findIndex(item => chair.exposedId === item.exposedId)
        : -1,
    );
  };

  const onChairCodeChanged = (code: string) => {
    if (currentChairEdit && chairIndex > -1) {
      setIsChanged(code !== subscription?.subscriptionItems[chairIndex].cardCodeValue);
      currentChairEdit.cardCodeValue = code;
    }
  };

  const onChairLabelChanged = (label: string) => {
    if (currentChairEdit && chairIndex > -1) {
      setIsChanged(label !== subscription?.subscriptionItems[chairIndex].label);
      currentChairEdit.label = label;
    }
  };

  const onChairStatusChanged = (status: string) => {
    if (currentChairEdit && chairIndex > -1) {
      setIsChanged(status !== subscription?.subscriptionItems[chairIndex].cardInstallStatus);
      currentChairEdit.cardInstallStatus = status as CardInstallStatusEnum;
    }
  };

  const onEditAddress = (address: Address) => {
    setScreenMode(SubscriptionScreenModeEnum.EDIT_ADDRESS);
    setCurrentAddressEdit({ ...address });
  };

  const onAddressChanged = (value: string, key: string) => {
    if (currentAddressEdit && subscription) {
      setIsChanged(value !== subscription[key as keyof Subscription]);
      currentAddressEdit[key as keyof Address] = value;
    }
  };

  const onReceiveInstallment = (row: SubscriptionPayment) => {
    setShowConfirmReceiveInstallment(true);
    setInstallmentMessage(subscriptionConfirmMessages.installment.message(row));
    setPaymentInstallment(row.installmentsCurrent);
  };

  const onRenewStatusChanged = (value: boolean) => {
    if (subscription === null) return;
    setIsReloadNecessary(false);
    if (value === false) {
      setShowConfirmDeactivateRenew(true);
    } else {
      dispatch(updateSubscriptionRenew(subscription.id, value));
    }
  };

  const onCancelReceiveInstallment = () => {
    setPaymentInstallment(0);
    setShowConfirmReceiveInstallment(false);
  };

  const onConfirmReceiveInstallment = () => {
    if (subscription === null) return;
    dispatch(receiveInstallment(subscription.id, subscription.purchaseOrderId, paymentInstallment));
  };

  const onConfirmRenewSubscription = () => {
    if (subscription === null) return;
    dispatch(updateSubscriptionRenew(subscription.id, false));
    setShowConfirmDeactivateRenew(false);
  };

  const onCancelRenewSubscription = () => {
    setShowConfirmDeactivateRenew(false);
    setIsReloadNecessary(true);
  };

  const onFinishProcessing = () => {
    setScreenMode(SubscriptionScreenModeEnum.VIEW);
    setPaymentInstallment(0);
    loadSubscription();
  };

  // componentDidMount
  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;

      // in case of refresh page
      if (!organizationIdSelected) {
        dispatch(fetchOrganizations(parseInt(orgId)));
        return;
      }

      if (!eventId || !orgId) {
        changeRoute(`${RoutesEnum.EVENT_MANAGER}`);
        return;
      }
    }
  }, [orgId, eventId, organizationIdSelected, dispatch, changeRoute]);

  useEffect(() => {
    if (mounted.current) {
      loadOccupancyData();
    }
  }, [loadOccupancyData]);

  // ComponentDidUpdate
  useEffect(() => {
    if (mounted.current && organizationIdSelected && organizationIdSelected !== parseInt(orgId)) {
      changeRoute(`${RoutesEnum.EVENT_MANAGER}`);
    }
  }, [organizationIdSelected, orgId, changeRoute]);

  useEffect(() => {
    if (mounted.current) {
      if (dialog?.type === 'success') {
        loadSubscriptions();
      }
    }
  }, [dialog, loadSubscriptions]);

  useEffect(() => {
    if (mounted.current) {
      uploadFile();
    }
  }, [uploadFile]);

  useEffect(() => {
    if (mounted.current) {
      loadSubscriptions();
    }
  }, [filterSelected, loadSubscriptions]);

  useEffect(() => {
    if (mounted.current && subscriptionSelected) {
      loadSubscription();
    }
  }, [subscriptionSelected, loadSubscription]);

  useEffect(() => {
    if (features && !features.subscriptionPageEnabled) {
      changeRoute(`${RoutesEnum.EVENT_MANAGER}`);
    }
  }, [changeRoute, features]);

  useEffect(() => {
    setScreenMode(SubscriptionScreenModeEnum.VIEW);
    setIsChanged(false);
  }, [subscription]);

  useEffect(() => {
    setIsEditable(!OWNER_DATA_NOT_EDITABLE_STATUS.includes(subscription?.overallItemPurchaseStatus!));
  }, [screenMode, subscription]);

  useEffect(() => {
    if (subscriptionSaved) {
      setShowMessage(true);
      setShowConfirmReceiveInstallment(false);

      if (paymentInstallment) {
        if (subscriptionSaved.type === 'error') {
          setPaymentInstallment(0);
        } else {
          setScreenMode(SubscriptionScreenModeEnum.PROCESSING_RECEIVE_INSTALLMENT);
        }
      } else {
        if (subscriptionSaved.type === 'success') {
          isReloadNecessary ? loadSubscriptions() : setIsReloadNecessary(true);
        }
      }
    }
    // eslint-disable-next-line
  }, [subscriptionSaved, loadSubscriptions]);

  return (
    <Layout enableBiletoTools={features?.biletoToolsEnabled}>
      <div className="pages">
        <Paper elevation="2" className="pages__card">
          <div className="pages__card-header">
            <h3 className="pages__title">Cadeiras</h3>
            <div className="pages__occupancy-group">
              <OccupancyCard
                className="pages__occupancy-card"
                value={occupancy?.totalAvailable}
                percentage={occupancy?.totalAvailablePercent}
                label="disponíveis"
              ></OccupancyCard>
              <OccupancyCard
                className="pages__occupancy-card"
                value={occupancy?.totalOccupied}
                percentage={occupancy?.totalOccupiedPercent}
                label="ocupadas"
              ></OccupancyCard>
              <OccupancyCard
                className="pages__occupancy-card pages--hide-mobile"
                value={occupancy?.totalCapacity}
                label="total"
              ></OccupancyCard>
            </div>
            <sb-button class="pages__button" onClick={() => exportFile('seats')}>
              exportar lista
            </sb-button>
          </div>
        </Paper>
        <Paper elevation="2" className="pages__card">
          <div className="pages__card-header">
            <div className="pages__card-menu">
              <h3 className="pages__title">Assinaturas</h3>
              <sb-icon-button
                class="pages__icon-button"
                icon={buttonGroupOpened ? 'chevron-up' : 'chevron-down'}
                onClick={() => changeButtonGroup()}
              ></sb-icon-button>
            </div>

            <div
              className={classname('pages__button-group', {
                'pages--group-closed': !buttonGroupOpened,
              })}
            >
              <FileField
                className="pages__button pages--reset-margin"
                label="Upload de arquivo"
                accept=".csv, .xlsx"
                styled="outline"
                onChange={onFileLoad}
                resetValue
              />
              <sb-button class="pages__button" onClick={() => exportFile('subscriptions')}>
                exportar lista
              </sb-button>
            </div>
          </div>
          <SubscriptionsFilterBox
            onSubmit={filterSubscriptions}
            onReset={resetFilter}
          ></SubscriptionsFilterBox>
          {pageAlert ? (
            <div className="pages__alert">
              <AlertMessage alert={pageAlert} onClick={loadSubscriptions} />
            </div>
          ) : (
            <>
              <SubscriptionsTable
                className="pages__table"
                loading={pageLoading}
                subscriptions={subscriptions}
                currentPage={currentPage}
                onChangePage={changeSubscriptionPage}
                onRowClicked={openDetails}
              ></SubscriptionsTable>
            </>
          )}
        </Paper>
      </div>
      <Dialog open={Boolean(dialog)} closeDisabled={dialog?.type === 'loading'} onClose={closeDialog}>
        {dialog && <AlertMessage alert={dialog} onClick={dialogClick} />}
      </Dialog>
      <Dialog
        open={Boolean(subscriptionSelected)}
        title={subscription ? 'Detalhes da assinatura' : ''}
        closeDisabled={showDiscardChanges || showConfirmReceiveInstallment || showConfirmDeactivateRenew}
        onClose={closeSubscriptionDetails}
        fullscreen
      >
        {showDiscardChanges ? (
          <ConfirmBox
            title="Descartar alterações?"
            message="Ao sair, todos os dados alterados serão perdidos."
            cancelButtonLabel="Continuar editando"
            confirmButtonLabel="Descartar"
            onCancel={onCancelDiscard}
            onConfirm={onConfirmDiscard}
          />
        ) : showConfirmReceiveInstallment ? (
          <ConfirmBox
            title="Realizar uma nova cobrança?"
            message={installmentMessage}
            cancelButtonLabel="Cancelar"
            confirmButtonLabel="Confirmar"
            loading={subscriptionSaving}
            onCancel={onCancelReceiveInstallment}
            onConfirm={onConfirmReceiveInstallment}
          />
        ) : showConfirmDeactivateRenew ? (
          <ConfirmBox
            title="Deseja desativar a renovação?"
            message={subscriptionConfirmMessages.renew.message}
            cancelButtonLabel="Voltar"
            confirmButtonLabel="Desativar renovação"
            onCancel={onCancelRenewSubscription}
            onConfirm={onConfirmRenewSubscription}
          />
        ) : subscription ? (
          <>
            <DialogHeaderComplement>
              {isEditable ? (
                <Button
                  styled="text"
                  disabled={screenMode !== SubscriptionScreenModeEnum.VIEW}
                  appearance="brand"
                  icon="pen-alt"
                  onClick={handleEditClick}
                >
                  editar dados
                </Button>
              ) : (
                ''
              )}
            </DialogHeaderComplement>
            <SubscriptionCard
              subscription={subscription}
              screenMode={screenMode}
              isEditable={isEditable}
              email={email}
              onEmailChanged={onEmailChanged}
              phone={phone}
              currentChairEdit={currentChairEdit}
              currentAddressEdit={currentAddressEdit}
              installmentProcessing={paymentInstallment}
              onPhoneChanged={onPhoneChanged}
              onValidate={onValidate}
              onValidateAddress={onValidateAddress}
              onEditChair={onEditChair}
              onEditAddress={onEditAddress}
              onChairCodeChanged={onChairCodeChanged}
              onChairLabelChanged={onChairLabelChanged}
              onAddressChanged={onAddressChanged}
              onChairStatusChanged={onChairStatusChanged}
              onReceiveInstallment={onReceiveInstallment}
              onRenewStatusChanged={onRenewStatusChanged}
              isChairUpdateFailed={subscriptionSaved ? subscriptionSaved.type === 'error' : false}
              onFinishProcessing={onFinishProcessing}
            />
            {screenMode !== SubscriptionScreenModeEnum.VIEW &&
              screenMode !== SubscriptionScreenModeEnum.PROCESSING_RECEIVE_INSTALLMENT && (
                <DialogFooter className="pages__dialog-footer">
                  <sb-button
                    class="pages__button-footer"
                    styled="text"
                    appearance="brand"
                    onClick={() =>
                      isChanged ? setShowDiscardChanges(true) : setScreenMode(SubscriptionScreenModeEnum.VIEW)
                    }
                  >
                    Cancelar
                  </sb-button>
                  <Button
                    className="pages__button-footer"
                    styled="solid"
                    appearance="brand"
                    loading={subscriptionSaving}
                    disabled={!enableSaveButton}
                    onClick={() => saveSubscription(subscription)}
                  >
                    Salvar
                  </Button>
                </DialogFooter>
              )}
          </>
        ) : subscriptionError ? (
          <AlertMessage alert={subscriptionError} onClick={loadSubscription} />
        ) : null}

        {subscriptionLoading && <sb-spinner class="pages__dialog-spinner"></sb-spinner>}
      </Dialog>
      <Snackbar
        open={showMessage}
        message={subscriptionSaved?.message}
        type={subscriptionSaved?.type}
        closeDisabled
        onClose={closeSnackbar}
      ></Snackbar>
    </Layout>
  );
};

const mapStateToProps = ({
  organizations,
  subscriptions: {
    dialog,
    page,
    pageLoading,
    pageAlert,
    subscription,
    loading: subscriptionLoading,
    error: subscriptionError,
    saving: subscriptionSaving,
    saved: subscriptionSaved,
    occupancy,
  },
  session: { user },
}: StoreState) => ({
  organizationIdSelected: organizations.selected?.id,
  dialog,
  subscription,
  subscriptionLoading,
  subscriptionError,
  subscriptionSaving,
  subscriptionSaved,
  features: user?.features,
  subscriptions: page,
  pageLoading,
  pageAlert,
  occupancy,
});

export default connect(mapStateToProps, {
  cleanDialogSubscription,
  exportSeats,
  exportSubscriptions,
  fetchOccupancy,
  fetchSubscription,
  fetchSubscriptions,
  receiveInstallment,
  resetSubscriptionSaved,
  updateSubscription,
  uploadSubscriptions,
})(SubscriptionsPage);
