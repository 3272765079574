import React, { useEffect, useRef } from 'react';
import 'sympla-bootstrap/dialog';

interface Props {
  children: React.ReactNode;
  className?: string;
}

export const DialogFooter: React.FC<Props> = ({ children, className }) => (
  <sb-dialog-footer class={className}>{children}</sb-dialog-footer>
);

export const DialogHeaderComplement: React.FC<Props> = ({ children }) => (
  <sb-dialog-header-complement>{children}</sb-dialog-header-complement>
);

interface DialogProps extends Props {
  open: boolean;
  title?: string;
  size?: string;
  fullscreen?: boolean;
  closeOutsideClick?: boolean;
  closeEscPress?: boolean;
  closeDisabled?: boolean;
  onClose?: () => void;
}

const Dialog: React.FC<DialogProps> = ({
  open,
  title,
  size,
  fullscreen,
  closeOutsideClick,
  closeEscPress,
  closeDisabled,
  children,
  onClose,
}: DialogProps) => {
  const dialogRef = useRef<HTMLElement>(null);
  const closeHandler = () => onClose && onClose();

  useEffect(() => {
    dialogRef?.current?.addEventListener('close', closeHandler);
    return () => dialogRef?.current?.removeEventListener('close', closeHandler);
  });

  return (
    <sb-dialog
      ref={dialogRef}
      open={open}
      title={title}
      size={size}
      fullscreen={fullscreen}
      closeOutsideClick={closeOutsideClick}
      closeEscPress={closeEscPress}
      closeDisabled={closeDisabled}
    >
      {children}
    </sb-dialog>
  );
};

Dialog.defaultProps = {
  title: '',
  fullscreen: false,
  closeOutsideClick: false,
  closeEscPress: false,
  closeDisabled: false,
  children: null,
  onClose: () => {},
};

export default Dialog;
