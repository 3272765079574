import React from 'react';
import './styles.scss';

interface RadioButtonOption {
  label: string;
  value: string;
}

interface RadioButtonGroupProps {
  className?: string;
  disabled?: boolean;
  name: string;
  options: RadioButtonOption[];
  valueSelected: string;
  onChange: (value: string) => void;
}

const RadioButtonGroup: React.FC<RadioButtonGroupProps> = ({
  className,
  disabled,
  name,
  options,
  valueSelected,
  onChange,
}) => {
  return (
    <>
      {options.map(item => {
        return (
          <div className="radio" key={item.value} onClick={() => onChange(item.value)}>
            <input
              disabled={disabled}
              className={className}
              name={name}
              type="radio"
              value={item.value}
              checked={valueSelected === item.value}
            />
            <label className="radio-label">{item.label}</label>
          </div>
        );
      })}
    </>
  );
};

RadioButtonGroup.defaultProps = {
  disabled: false,
};

export default RadioButtonGroup;
