import React, { useEffect, useRef, useState } from 'react';

import TextField from '../../components/TextField';
import Icon from '../../components/Icon';
import './styles.scss';

interface SearchFieldProps {
  className?: string;
  searchValue: string | '';
  setSearchValue: (value: string) => void;
  onSubmit?: (value: string) => void;
}

const minLength = 3;

const SearchField: React.FC<SearchFieldProps> = ({
  className,
  searchValue,
  setSearchValue,
  onSubmit,
}: SearchFieldProps) => {
  const formRef = useRef<HTMLFormElement>(null);
  const inputSearchRef = useRef<SbTextFieldElement>(null);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  useEffect(() => {
    formRef?.current?.addEventListener('keydown', handlerKeydown);
    return () => {
      formRef?.current?.removeEventListener('keydown', handlerKeydown);
    };
  });

  useEffect(() => {
    if (searchValue === '') {
      inputSearchRef.current!.value = '';
      setShowErrorMessage(false);
    }
  }, [searchValue]);

  const handlerKeydown = (e: KeyboardEvent) => {
    const { code, key } = e;
    if (['Enter', 'NumpadEnter'].includes(code || key)) {
      handlerSubmit();
    }
  };

  const handlerSubmit = () => {
    const isValid = searchValue.length >= minLength;
    setShowErrorMessage(searchValue.length < minLength);

    if (isValid && onSubmit) {
      onSubmit(searchValue);
    }
  };

  return (
    <form ref={formRef} noValidate onSubmit={e => e.preventDefault()} className={className}>
      <TextField
        className="search-field__input"
        defaultValue={searchValue}
        ref={inputSearchRef}
        min={minLength}
        invalid={showErrorMessage}
        type="search"
        placeholder="Busque pelo nome ou número da assinatura"
        errorMessage="Digite pelo menos 3 caracteres"
        autocomplete="off"
        onInput={value => setSearchValue(value.trim())}
      >
        <Icon icon="search" slot="text-field-sufix" className="pages__icon" onClick={handlerSubmit} />
      </TextField>
    </form>
  );
};

export default SearchField;
