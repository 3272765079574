import { Dispatch } from 'react';
import { organizerApi } from '../api';
import { Organization } from '../model/organization';

export const ORGANIZATION_ADD = 'ORGANIZATION_ADD';
export const ORGANIZATION_SELECT = 'ORGANIZATION_SELECT';

export const addOrganizations = (payload: Organization[]) => ({
  type: ORGANIZATION_ADD,
  payload,
});

export const selectOrganization = (payload: Organization | null) => ({
  type: ORGANIZATION_SELECT,
  payload,
});

export const fetchOrganizations = (organizationSelected?: number | null) => async (
  dispatch: Dispatch<any>,
) => {
  try {
    const { data } = await organizerApi.getAll();
    if (data && data.length) {
      let prefOrg;
      if (organizationSelected) {
        prefOrg = data.find((org) => org.id === organizationSelected);
      }
      const organization = prefOrg ?? data[0];

      dispatch(addOrganizations(data));
      dispatch(selectOrganization(organization));
    }
  } catch {}
};