import {
  DialogUploadResponse,
  SubscriptionPage,
  Subscription,
  Occupancy,
  SeatItem,
  Address,
} from '../model/subscription';
import { subscriptionGateway } from './apiGateway';
import { AxiosRequestConfig } from 'axios';
import { CardCodeVendorEnum } from '../enums/subscription';

const downloadFilesConfigs: AxiosRequestConfig = {
  headers: {
    'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  },
  responseType: 'blob',
};

export const subscriptionApi = {
  exportSeats: (eventId: string) =>
    subscriptionGateway.get(`/api/v1/seats/spreadsheet?eventId=${eventId}`, downloadFilesConfigs),
  exportSubscriptions: (eventId: string) =>
    subscriptionGateway.get(`/api/v1/subscriptions/spreadsheet?eventId=${eventId}`, downloadFilesConfigs),
  uploadSubscriptions: (data: FormData, eventId: string) =>
    subscriptionGateway.post<DialogUploadResponse>(`/api/v1/subscriptions/import?eventId=${eventId}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      timeout: 0,
    }),
  getSubscriptions: (eventId: string, page: number, searchQuery: string, filterQuery: string) =>
    subscriptionGateway.get<SubscriptionPage>(
      `/api/v1/subscriptions?size=15&eventId=${eventId}&page=${page}${searchQuery}${filterQuery}`,
    ),
  getSubscription: (id: string) => subscriptionGateway.get<Subscription>(`/api/v1/subscriptions/${id}`),
  updateSubscriptionPhoneEmail: (id: string, email: string, phone: string) =>
    subscriptionGateway.patch<Subscription>(`/api/v1/subscriptions/${id}`, {
      subscriber_email: email,
      subscriber_phone: phone,
    }),
  updateSubscriptionAddress: (id: string, address: Address) =>
    subscriptionGateway.patch<Subscription>(`/api/v1/subscriptions/${id}`, {
      recipient_address: address.recipientAddress,
      recipient_address_complement: address.recipientAddressComplement,
      recipient_address_number: address.recipientAddressNumber,
      recipient_postal_code: address.recipientPostalCode,
      recipient_district: address.recipientDistrict,
      recipient_city: address.recipientCity,
      recipient_state: address.recipientState,
      recipient_name: address.recipientName,
    }),
  updateSubscriptionChair: (id: string, chair: SeatItem) =>
    subscriptionGateway.patch<Subscription>(`/api/v1/subscriptions/${id}/seat/${chair.exposedId}`, {
      label: chair.label,
      card_code_value: chair.cardCodeValue,
      card_install_status: chair.cardInstallStatus,
      card_code_vendor: CardCodeVendorEnum.MIFARE,
    }),
  updateSubscriptionRenew: (id: string, renew: boolean) =>
    subscriptionGateway.patch<Subscription>(`/api/v1/subscriptions/${id}`, {
      renew: renew,
    }),
  getOccupancy: (eventId: string) =>
    subscriptionGateway.get<Occupancy>(`/api/v1/seats/occupancy?eventId=${eventId}`),
  receiveInstallment: (id: string, purchaseOrderId: number, installment: number) =>
    subscriptionGateway.post<any>(
      `/api/v1/subscriptions/${id}/purchases/${purchaseOrderId}/installments/${installment}`,
      null,
      {
        headers: {
          'Content-Type': 'application/json',
        },
        timeout: 0,
      },
    ),
};
