import React from 'react';
import Panel from '../../../../components/Panel';
import './styles.scss';
import { PlanItem } from '../../../../model/plan';
import { currencyFormatterFromApi } from '../../../../services/currencyService';

interface ExpandableDetailsProps {
  data?: PlanItem;
  onDetailsClick: (row: PlanItem, e?: MouseEvent) => void;
}

const ExpandableDetails: React.FC<ExpandableDetailsProps> = ({ data, onDetailsClick }) => {
  if (!data) return null;
  const { value } = data;

  return (
    <div className="details">
      <div className="details__card">
        <Panel
          label="Valor"
          value={`R$ ${currencyFormatterFromApi(Number(value))}`}
          className="details--column-2"
        />
        <sb-button class="details--column-2" onClick={() => onDetailsClick(data)} styled="outline">
          Mais Detalhes
        </sb-button>
      </div>
    </div>
  );
};

export default ExpandableDetails;
