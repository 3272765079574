import React from 'react';
import Button from '../Button';
import './styles.scss';

interface ConfirmBox {
  title: string;
  message: string;
  cancelButtonLabel: string;
  confirmButtonLabel: string;
  loading?: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

const ConfirmBox: React.FC<ConfirmBox> = ({
  title,
  message,
  cancelButtonLabel,
  confirmButtonLabel,
  loading,
  onCancel,
  onConfirm,
}) => {

  const getMessageMarkup = () => {
    return { __html: message };
  };

  return (
    <div className="card-updater__content">
      <div className="card-updater__abandon">
        <sb-icon class="card-updater__abandon-icon" icon="exclamation-triangle"></sb-icon>
        <div className="card-updater__abandon-title">{title}</div>
        <div className="card-updater__abandon-description" dangerouslySetInnerHTML={getMessageMarkup()}></div>
        <div className="card-updater__abandon-buttons">
          <Button
            className="card-updater__abandon-button"
            styled="solid"
            onClick={onConfirm}
            loading={loading}
            >
            {confirmButtonLabel}
          </Button>
          <Button
            className="card-updater__abandon-button"
            styled="outline"
            onClick={onCancel}
            disabled={loading}
          >
            {cancelButtonLabel}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmBox;
