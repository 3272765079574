import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { ActionTypes } from '../actions/actionTypes';
import {
  REACT_APP_EVENT_MANAGER_API,
  REACT_APP_SUBSCRIPTION_API,
  REACT_APP_USER_COOKIE_NAME,
} from '../constants';
import cookie from 'react-cookies';
import { DynamicObject } from '../model/general';

const TIMEOUT = 5000;
const NON_CONVERSION_TYPES = ['blob'];

const isObject = function (o: any) {
  return o === Object(o) && !Array.isArray(o) && typeof o !== 'function';
};

const toCamel = (key: string): string => key.replace(/_(\w)/g, (match, g) => g.toUpperCase());

const keysToCamel = function (o: any): any {
  if (isObject(o)) {
    return Object.entries(o).reduce((aggr: DynamicObject, item) => {
      const [key, value] = item;

      aggr[toCamel(key)] = keysToCamel(value);
      return aggr;
    }, {});
  }
  if (Array.isArray(o)) {
    return o.map(keysToCamel);
  }

  return o;
};

const requestInterceptor = (config: AxiosRequestConfig, dispatch: Dispatch, useAuthorization?: boolean) => {
  const { url } = config;

  if (url === '/auth/login') {
    return config;
  }

  const user = cookie.load(REACT_APP_USER_COOKIE_NAME);
  if (!user || !user.token) {
    dispatch({ type: ActionTypes.LOGOUT });

    throw new axios.Cancel('Unauthorized');
  }

  useAuthorization
    ? (config.headers['Authorization'] = `Bearer ${user.token}`)
    : (config.headers['x-auth-token'] = user.token);

  return config;
};

const responseSuccessInterceptor = (response: AxiosResponse) => {
  if (!NON_CONVERSION_TYPES.includes(response.request.responseType)) {
    response.data = keysToCamel(response.data);
  }

  return response;
};

const responseErrorInterceptor = (error: AxiosError, dispatch: Dispatch) => {
  if (error.response?.status === 401) {
    dispatch({ type: ActionTypes.LOGOUT });
  }

  return Promise.reject(error);
};

const apiGateway = axios.create({
  baseURL: REACT_APP_EVENT_MANAGER_API,
  timeout: TIMEOUT,
});

const subscriptionGateway = axios.create({
  baseURL: REACT_APP_SUBSCRIPTION_API,
  timeout: TIMEOUT,
});

const setupInterceptors = ({ dispatch }: { dispatch: Dispatch<any> }) => {
  apiGateway.interceptors.request.use(
    (config: AxiosRequestConfig) => requestInterceptor(config, dispatch),
    error => Promise.reject(error),
  );

  subscriptionGateway.interceptors.request.use(
    (config: AxiosRequestConfig) => requestInterceptor(config, dispatch, true),
    error => Promise.reject(error),
  );

  subscriptionGateway.interceptors.response.use(responseSuccessInterceptor, error =>
    responseErrorInterceptor(error, dispatch),
  );
};

export { apiGateway, subscriptionGateway, setupInterceptors };
