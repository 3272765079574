import cookie from 'react-cookies';
import { REACT_APP_USER_COOKIE_NAME } from '../constants';
import { PermissionEnum } from '../enums/permission';

export const userAllow = (permissions: PermissionEnum[]) => {
  const { perms } = cookie.load(REACT_APP_USER_COOKIE_NAME) || {};
  if (!perms) {
    return false;
  }
  const formattedPerms = perms.split('.');
  return formattedPerms.some((p: PermissionEnum) => permissions?.includes(p));
};
