import React, { useCallback, useEffect, useRef, useState } from 'react';
import { History } from 'history';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import '../../styles/pages.scss';
import { PlanStoreState } from '../../model/store';
import {
  addPlan,
  addPlans,
  createPlan,
  fetchPlan,
  fetchPlans,
  resetPlanSaved,
  updatePlan,
} from '../../actions/plan';
import { RoutesEnum } from '../../enums/routes';
import { Alert, SnackbarAlert, DynamicObject, RoutesParams } from '../../model/general';
import AlertMessage from '../../components/AlertMessage';
import PlansTable from './PlansTable';
import 'sympla-bootstrap/icon-button';
import { fetchOrganizations } from '../../actions/organizations';
import { PlanItem, PlanPage } from '../../model/plan';
import Layout from '../../components/Layout';
import Dialog, { DialogFooter, DialogHeaderComplement } from '../../components/Dialog';
import PlanCard from './PlansCard';
import Button from '../../components/Button';
import { PlanScreenModeEnum } from '../../enums/plan';
import PageList from '../../components/PageList';
import ConfirmBox from '../../components/ConfirmBox';

interface PlansProps {
  organizationIdSelected?: number;
  history: History;
  features?: DynamicObject<boolean>;
  plan: PlanItem | null;
  plans: PlanPage | null;
  planLoading: boolean;
  planError: Alert | null;
  planSaving: boolean;
  planSaved: SnackbarAlert | null;
  pageLoading: boolean;
  pageAlert: Alert | null;
  fetchPlans: (page: number) => void;
  resetPlanSaved: () => void;
}

const PlansPage: React.FC<PlansProps> = ({
  history,
  organizationIdSelected,
  fetchPlans,
  resetPlanSaved,
  features,
  plan,
  plans,
  pageLoading,
  pageAlert,
  planLoading,
  planError,
  planSaving,
  planSaved,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [showMessage, setShowMessage] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [name, setName] = useState('');
  const [value, setValue] = useState('');
  const [planSelected, setPlanSelected] = useState<PlanItem | null>(null);
  const [screenMode, setScreenMode] = useState<PlanScreenModeEnum | null>(null);
  const [enableSaveButton, setEnableSaveButton] = useState(true);
  const [showDiscardChanges, setShowDiscardChanges] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const mounted = useRef(false);
  const { orgId, eventId }: RoutesParams = useParams();
  const dispatch = useDispatch();

  const changeRoute = useCallback((route: string) => history.push(route), [history]);

  const loadPlans = useCallback(
    (page = 1) => {
      setCurrentPage(page);
      fetchPlans(page - 1);
    },
    [fetchPlans],
  );

  const loadPlan = useCallback(() => {
    dispatch(fetchPlan(planSelected?.exposedId!));
  }, [planSelected, dispatch]);

  const changePlanPage = (currentPage: number) => {
    loadPlans(currentPage);
  };

  const closeSnackbar = () => {
    setShowMessage(false);
    dispatch(resetPlanSaved());
  };

  const openPlanDetails = (planSelected: PlanItem) => {
    setScreenMode(PlanScreenModeEnum.VIEW);
    setPlanSelected(planSelected);
    setIsDialogOpen(true);
  };

  const openDialogCreateNewPlan = () => {
    setScreenMode(PlanScreenModeEnum.CREATE);
    setIsDialogOpen(true);
  };

  const closePlanDetails = () => {
    clearFields();
    setScreenMode(null);
    setPlanSelected(null);
    setIsDialogOpen(false);
  };

  const onNameChanged = (name: string) => {
    setIsChanged(true);
    setName(name);
  };

  const onValueChanged = (value: string) => {
    setIsChanged(true);
    setValue(value);
  };

  const onValidate = (valid: boolean) => setEnableSaveButton(valid);

  const createNewPlan = async (plan: PlanItem | null) => {
    if (plan === null) return;
    dispatch(createPlan(plan));
  };

  const updatePlanData = async (plan: PlanItem | null) => {
    if (plan === null) return;
    dispatch(updatePlan(plan.exposedId!, plan));
  };

  const onCancelDiscard = () => {
    setShowDiscardChanges(false);
  };

  const onConfirmDiscard = () => {
    clearFields();
    setScreenMode(null);
    setPlanSelected(null);
    setShowDiscardChanges(false);
    if (screenMode === PlanScreenModeEnum.CREATE) setIsDialogOpen(false);
  };

  const clearFields = () => {
    setValue('');
    setName('');
    setIsChanged(false);
  };

  const handleEditClick = (e: Event) => {
    setScreenMode(PlanScreenModeEnum.EDIT);
    setName(planSelected?.name || '');
    setValue(planSelected?.value || '');
  };

  // componentDidMount
  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;

      // in case of refresh page
      if (!organizationIdSelected) {
        dispatch(fetchOrganizations(parseInt(orgId)));
        return;
      }

      if (!eventId || !orgId) {
        changeRoute(`${RoutesEnum.EVENT_MANAGER}`);
        return;
      }
    }
  }, [orgId, eventId, organizationIdSelected, dispatch, changeRoute]);

  useEffect(() => {
    if (mounted.current) {
      loadPlans();
    }
  }, [loadPlans]);

  useEffect(() => {
    if (mounted.current && planSelected) {
      loadPlan();
    }
  }, [planSelected, loadPlan]);

  useEffect(() => {
    if (mounted.current && organizationIdSelected && organizationIdSelected !== parseInt(orgId)) {
      changeRoute(`${RoutesEnum.EVENT_MANAGER}`);
    }
  }, [organizationIdSelected, orgId, changeRoute]);

  useEffect(() => {
    if (features && !features.subscriptionPageEnabled) {
      changeRoute(`${RoutesEnum.EVENT_MANAGER}`);
    }
  }, [changeRoute, features]);

  useEffect(() => {
    if (planSaved) {
      setShowMessage(true);
      if (planSaved.type === 'success') {
        loadPlans();
        clearFields();
        if (screenMode === PlanScreenModeEnum.CREATE) {
          closePlanDetails();
        } else {
          setPlanSelected(plan);
          setScreenMode(PlanScreenModeEnum.VIEW);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planSaved, loadPlans]);

  return (
    <Layout enableBiletoTools={features?.biletoToolsEnabled}>
      <PageList
        title="Meus Planos"
        topRightChildren={
          <sb-button class="pages__button" onClick={openDialogCreateNewPlan}>
            criar plano
          </sb-button>
        }
        alert={pageAlert}
        loadData={loadPlans}
        table={
          <PlansTable
            className="pages__table"
            loading={pageLoading}
            plans={plans}
            currentPage={currentPage}
            onChangePage={changePlanPage}
            onRowClicked={openPlanDetails}
          ></PlansTable>
        }
        snackbarProps={{ showMessage, closeSnackbar, message: planSaved?.message, type: planSaved?.type }}
      >
        <Dialog
          open={isDialogOpen}
          title={
            plans ? (screenMode === PlanScreenModeEnum.CREATE ? 'Criar Plano' : 'Detalhes do Plano') : ''
          }
          onClose={() => (isChanged ? setShowDiscardChanges(true) : closePlanDetails())}
          fullscreen
        >
          {showDiscardChanges ? (
            <ConfirmBox
              title="Descartar alterações?"
              message="Ao sair, todos os dados alterados serão perdidos."
              cancelButtonLabel={
                screenMode === PlanScreenModeEnum.CREATE ? 'Continuar criando' : 'Continuar Editando'
              }
              confirmButtonLabel="Descartar"
              onCancel={onCancelDiscard}
              onConfirm={onConfirmDiscard}
            />
          ) : (
            <>
              <>
                <DialogHeaderComplement>
                  {screenMode !== PlanScreenModeEnum.CREATE ? (
                    <Button
                      styled="text"
                      disabled={screenMode === PlanScreenModeEnum.EDIT}
                      appearance="brand"
                      icon="pen-alt"
                      onClick={handleEditClick}
                    >
                      editar dados
                    </Button>
                  ) : (
                    ''
                  )}
                </DialogHeaderComplement>
                <PlanCard
                  plan={plan}
                  screenMode={screenMode}
                  onNameChanged={onNameChanged}
                  onValueChanged={onValueChanged}
                  onValidate={onValidate}
                  editName={name}
                  editValue={value}
                />
                {screenMode === PlanScreenModeEnum.CREATE || screenMode === PlanScreenModeEnum.EDIT ? (
                  <DialogFooter className="pages__dialog-footer">
                    <sb-button
                      class="pages__button-footer"
                      styled="text"
                      appearance="brand"
                      onClick={() =>
                        isChanged
                          ? setShowDiscardChanges(true)
                          : screenMode === PlanScreenModeEnum.EDIT
                          ? setScreenMode(PlanScreenModeEnum.VIEW)
                          : closePlanDetails()
                      }
                    >
                      Cancelar
                    </sb-button>
                    <Button
                      className="pages__button-footer"
                      styled="solid"
                      appearance="brand"
                      loading={planSaving}
                      disabled={!enableSaveButton}
                      onClick={() =>
                        screenMode === PlanScreenModeEnum.CREATE
                          ? createNewPlan({ name, value })
                          : updatePlanData({
                              exposedId: plan?.exposedId,
                              name,
                              value,
                              sectors: plan?.sectors,
                            })
                      }
                    >
                      Salvar
                    </Button>
                  </DialogFooter>
                ) : (
                  ''
                )}
              </>
              {planError && <AlertMessage alert={planError} onClick={() => {}} />}
              {planLoading && <sb-spinner class="pages__dialog-spinner"></sb-spinner>}
            </>
          )}
        </Dialog>
      </PageList>
    </Layout>
  );
};

const mapStateToProps = ({
  plans: {
    page,
    pageLoading,
    pageAlert,
    plan,
    loading: planLoading,
    error: planError,
    saving: planSaving,
    saved: planSaved,
  },
  session: { user },
}: PlanStoreState) => ({
  features: user?.features,
  plan,
  plans: page,
  pageLoading,
  pageAlert,
  planLoading,
  planError,
  planSaving,
  planSaved,
});

export default connect(mapStateToProps, {
  addPlan,
  addPlans,
  fetchPlan,
  fetchPlans,
  resetPlanSaved,
})(PlansPage);
