import { combineReducers } from 'redux';
import organizations from './organizations';
import subscriptions from './subscriptions';
import session from './session';
import plans from './plans';

export const reducers = combineReducers({
  organizations,
  subscriptions,
  session,
  plans,
});
