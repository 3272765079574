import React from 'react';
import Routes from './pages/routes';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import { reducers } from './reducers';
import thunk from 'redux-thunk';
import { setupInterceptors } from './api/apiGateway';

const Store = createStore(reducers, applyMiddleware(thunk));
setupInterceptors(Store);

const App = () => (
  <Provider store={Store}>
    <Routes />
  </Provider>
);

export default App;
