import React from 'react';
import Tag from '../../../components/Tag';
import {
  OverallItemCardInstallStatusValue, PurchaseStatusValue,
} from '../../../enums/subscription';
import { capitalizeAll, toLocaleDate } from 'sympla-bootstrap/utils';
import { SubscriptionItem } from '../../../model/subscription';

export const subscriptionsTableColumns = [
  {
    name: 'Nome',
    selector: 'subscriberName',
    sortable: true,
    format: (row: SubscriptionItem) => `${capitalizeAll(row.subscriberName.toLowerCase())}`,
    grow: 2,
  },
  {
    name: 'E-mail',
    selector: 'subscriberEmail',
    sortable: true,
    hide: 1088,
    grow: 3,
  },
  {
    name: 'Cadeiras',
    selector: 'subscriptionItemCount',
    sortable: true,
    hide: 640,
  },
  {
    name: 'Instalação da placa',
    selector: 'overallItemCardInstallStatus',
    sortable: true,
    cell: (row: SubscriptionItem) => {
      const { tag, value } = OverallItemCardInstallStatusValue[row.overallItemCardInstallStatus];
      return (
        <Tag appearance={tag} styled="pale">
          {value}
        </Tag>
      );
    },
    hide: 640,
  },
  {
    name: 'Nº da assinatura',
    selector: 'purchaseOrderId',
    sortable: true,
    hide: 400,
  },
  {
    name: 'Início da assinatura',
    selector: 'termStart',
    sortable: true,
    format: (row: SubscriptionItem) => `${toLocaleDate(row.termStart)}`,
    hide: 890,
  },
  {
    name: 'Fim da assinatura',
    selector: 'termEnd',
    sortable: true,
    format: (row: SubscriptionItem) => `${toLocaleDate(row.termEnd)}`,
    hide: 890,
  },
  {
    name: 'Status',
    selector: 'overallItemPurchaseStatus',
    sortable: true,
    cell: (row: SubscriptionItem) => {
      const { tag, value } = PurchaseStatusValue[row.overallItemPurchaseStatus];
      return (
        <Tag appearance={tag} styled="pale">
          {value}
        </Tag>
      );
    },
  },
];

export const navigationOptions = {
  rowsPerPageText: '',
  rangeSeparatorText: 'resultados de',
  noRowsPerPage: true,
  selectAllRowsItem: false,
  selectAllRowsItemText: '',
};

export const customStyles = {
  headCells: {
    style: {
      fontSize: 'var(--font-tiny)',
      color: 'var(--neutral-dark-pure)',
      fontWeight: 'var(--weight-semibold)',
    },
    inactiveSortStyle: {
      '&:hover:not(:focus)': {
        color: 'var(--neutral-dark-medium)',
      },
    },
  },
  pagination: {
    pageButtonsStyle: {
      'fill': 'var(--brand-primary-pure)',
      '&:disabled': {
        fill: 'var(--neutral-dark-pale)',
      },
    },
  },
};
