import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { RoutesParams } from '../../model/general';
import './styles.scss';

const TabBar: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const { orgId, eventId }: RoutesParams = useParams();
  const urlParams = `/organizations/${orgId}/events/${eventId}`;
  const checkIncludes = (param: string) => location.pathname.includes(param);
  const tabs = [
    {
      name: 'ASSINATURAS',
      current: checkIncludes('subscriptions'),
      href: `${urlParams}/subscriptions`,
    },
    { name: 'PLANOS', current: checkIncludes('plans'), href: `${urlParams}/plans` },
  ];

  return (
    <nav className="tab__container" aria-label="Tabs">
      {tabs.map(tab => (
        <button
          key={tab.name}
          className={`${tab.current ? 'tab--active' : ''} tab__option`}
          onClick={() => {
            history.push(tab.href);
          }}
        >
          {tab.name}
        </button>
      ))}
    </nav>
  );
};

export default TabBar;
