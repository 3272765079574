import { PaymentMethodsEnum } from '../../../enums/subscription';

export const ignoredPaymentTypesArray = [
  PaymentMethodsEnum.BOLETO,
  PaymentMethodsEnum.FREE,
  PaymentMethodsEnum.POS,
];

export const navigationOptions = {
  rowsPerPageText: '',
  rangeSeparatorText: 'resultados de',
  noRowsPerPage: true,
  selectAllRowsItem: false,
  selectAllRowsItemText: '',
};

export const customStyles = {
  headCells: {
    style: {
      fontSize: 'var(--font-tiny)',
      color: 'var(--neutral-dark-pure)',
      fontWeight: 'var(--weight-semibold)',
    },
    inactiveSortStyle: {
      '&:hover:not(:focus)': {
        color: 'var(--neutral-dark-medium)',
      },
    },
  },
  pagination: {
    pageButtonsStyle: {
      'fill': 'var(--brand-primary-pure)',
      '&:disabled': {
        fill: 'var(--neutral-dark-pale)',
      },
    },
  },
};
