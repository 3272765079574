import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'sympla-bootstrap/styles/main.css';
import 'sympla-bootstrap/styles/OpenSans.css';
import 'sympla-bootstrap/button';
import 'sympla-bootstrap/text-field';
import 'sympla-bootstrap/password-field';
import 'sympla-bootstrap/icon';
import 'sympla-bootstrap/avatar';
import 'sympla-bootstrap/list';
import 'sympla-bootstrap/paper';
import './styles/styles.scss';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
