import React from 'react';
import { useTable, Column } from 'react-table';
import './styles.scss';

interface CardResponsiveProps {
  columns: Column[];
  data: {}[];
  className?: string;
}

const CardResponsive: React.FC<CardResponsiveProps> = ({ columns, data, className }) => {
  const { rows, prepareRow } = useTable({
    columns,
    data,
  });

  return (
    <div className="card-responsive">
      {rows.map(row => {
        prepareRow(row);
        return (
          <div key={row.id} className={`card-responsive__row ${className}`}>
            {row.cells.map(cell => (
              <div className="card-responsive__cell" {...cell.getCellProps()}>
                <div className="card-responsive__label">{cell.render('Header')}</div>
                <span className="">{cell.render('Cell')}</span>
              </div>
            ))}
          </div>
        );
      })}
    </div>
  );
};

export default CardResponsive;
