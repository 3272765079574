import React, { useEffect, useState } from 'react';
import './styles.scss';

interface ToggleSwitchProps {
  disabled?: boolean;
  defaultChecked: boolean;
  className?: string;
  onChange?: (toggle: boolean) => void;
}

const ToggleSwitch = ({ disabled = false, defaultChecked, onChange }: ToggleSwitchProps) => {
  const [on, setOn] = useState(defaultChecked);

  const triggerToggle = () => {
    if (disabled) return;
    if (onChange) onChange(!on);
    setOn(!on);
  };

  useEffect(() => {
    setOn(defaultChecked);
  }, [defaultChecked]);

  return (
    <div
      onClick={triggerToggle}
      className={`toggle ${on ? 'toggle--checked' : ''} ${disabled ? 'toggle--disabled' : ''}`}
    >
      <div className="toggle__container">
        <div className="toggle__check">
          <span className="toggle__label">Ativo</span>
        </div>
        <div className="toggle__uncheck">
          <span className="toggle__label">Inativo</span>
        </div>
      </div>
      <div className="toggle__circle"></div>
      <input className="toggle__input" type="checkbox" aria-label="Toggle Button" />
    </div>
  );
};

export default ToggleSwitch;
