import React, { useState } from 'react';
import { CardInstallStatusEnum, PurchaseStatusEnum, PurchaseStatusValue } from '../../enums/subscription';
import Button from '../Button';
import Dialog from '../Dialog';
import InputCheckbox from '../InputCheckbox';
import RadioButtonGroup from '../RadioButtonGroup';
import SearchField from '../SearchField';
import Tag from '../Tag';
import './styles.scss';

interface SubscriptionsFilterBoxProps {
  onReset?: () => void;
  onSubmit?: (
    value: string,
    filterStatusSelected: string[],
    endNextDays: string,
    chairStatus: CardInstallStatusEnum | '',
  ) => void;
}

const SubscriptionsFilterBox: React.FC<SubscriptionsFilterBoxProps> = ({
  onReset,
  onSubmit,
}: SubscriptionsFilterBoxProps) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [showResetButton, setShowResetButton] = useState(false);
  const [isOpenFilterSubscriptions, setIsOpenFilterSubscriptions] = useState(false);
  const [filterCheckState, setFilterCheckState] = useState<boolean[]>(
    Object.keys(PurchaseStatusEnum).map(() => false),
  );
  const [endNextDays, setEndNextDays] = useState<string>('');
  const [chairStatus, setChairStatus] = useState<CardInstallStatusEnum | ''>('');
  const [filterCounter, setFilterCounter] = useState<number>(0);

  const fillCheckboxStatus = Object.values(PurchaseStatusEnum);

  const endNextDaysFilters = [
    {
      label: 'Todas',
      value: '',
    },
    {
      label: 'Próximos 7 dias',
      value: '7',
    },
    {
      label: 'Próximos 30 dias',
      value: '30',
    },
    {
      label: 'Próximos 90 dias',
      value: '90',
    },
  ];

  const chairStatusFilters = [
    {
      label: 'Todas',
      value: '',
    },
    {
      label: 'Instalada',
      value: CardInstallStatusEnum.INSTALLED,
    },
    {
      label: 'Não Instalada',
      value: CardInstallStatusEnum.NOT_INSTALLED,
    },
  ];

  const handlerResetSearch = () => {
    setSearchValue('');
    setShowResetButton(false);
    setFilterCounter(0);
    setFilterCheckState(Object.keys(PurchaseStatusEnum).map(() => false));
    setEndNextDays('');
    setChairStatus('');
    onReset && onReset();
  };

  const filterDialogOpen = () => {
    setIsOpenFilterSubscriptions(true);
  };

  const filterDialogClose = () => {
    setIsOpenFilterSubscriptions(false);
  };

  const handleSelectCheckbox = (position: number) => {
    filterCheckState[position] = !filterCheckState[position];
    const updatedFilterCheckState = [...filterCheckState];
    setFilterCheckState(updatedFilterCheckState);
  };

  const handleFilterSearchClick = () => {
    submitFilter(searchValue);
    filterDialogClose();
  };

  const handlerSearchFieldSubmit = (value: string) => {
    setSearchValue(value);
    submitFilter(value);
  };

  const handleChangeChairStatus = (value: string) => {
    setChairStatus(value as CardInstallStatusEnum);
  };

  const submitFilter = (termSearched: string) => {
    const filterStatusSelected = fillCheckboxStatus.filter((_, index) => filterCheckState[index]);
    const totalFilters =
      filterStatusSelected.length + (endNextDays !== '' ? 1 : 0) + (chairStatus !== '' ? 1 : 0);
    setFilterCounter(totalFilters);
    onSubmit && onSubmit(termSearched, filterStatusSelected, endNextDays, chairStatus);
    setShowResetButton(
      filterStatusSelected.length > 0 || termSearched !== '' || endNextDays !== '' || chairStatus !== '',
    );
  };

  return (
    <>
      <Dialog
        open={Boolean(isOpenFilterSubscriptions)}
        size="xs"
        title="Filtrar por"
        onClose={filterDialogClose}
      >
        <div className="filter-box__dialog">
          <h4 className="filter-box__title">Status da assinatura</h4>
          {fillCheckboxStatus.map((status, index) => (
            <InputCheckbox
              key={index}
              index={index}
              isChecked={!!filterCheckState[index]}
              name={PurchaseStatusValue[status].value}
              onChange={() => handleSelectCheckbox(index)}
            />
          ))}
          <h4 className="filter-box__title">Fim da assinatura</h4>
          <RadioButtonGroup
            key="endNextDaysFilter"
            name="endNextDaysFilter"
            options={endNextDaysFilters}
            valueSelected={endNextDays}
            onChange={setEndNextDays}
          ></RadioButtonGroup>
          <h4 className="filter-box__title">Status da placa</h4>
          <RadioButtonGroup
            key="chairStatusFilter"
            name="chairStatusFilter"
            options={chairStatusFilters}
            valueSelected={chairStatus}
            onChange={handleChangeChairStatus}
          ></RadioButtonGroup>
          <Button styled="solid" onClick={handleFilterSearchClick}>
            Filtrar
          </Button>
        </div>
      </Dialog>
      <div className="filter-box__form">
        <SearchField
          className="filter-box__search-field"
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          onSubmit={handlerSearchFieldSubmit}
        ></SearchField>
        <Button icon="slider-h" styled="text" size="medium" onClick={filterDialogOpen}></Button>
        {showResetButton && (
          <Button className="filter-box__button" styled="text" onClick={handlerResetSearch}>
            Limpar busca
          </Button>
        )}
      </div>
      <div className="filter-box__info">
        {filterCounter > 0 && (
          <Tag styled="pale" className="filter-box__tag">
            <span onClick={filterDialogOpen}>
              {filterCounter} filtro{filterCounter > 1 && 's'} ativo
              {filterCounter > 1 && 's'}
            </span>
          </Tag>
        )}
        {showResetButton && (
          <Button className="filter-box__mobile-button" styled="text" onClick={handlerResetSearch}>
            Limpar busca
          </Button>
        )}
      </div>
    </>
  );
};

export default SubscriptionsFilterBox;
