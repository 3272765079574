import { PlanItem } from '../../../model/plan';
import { currencyFormatterFromApi } from '../../../services/currencyService';

export const plansTableColumns = [
  {
    name: 'Nome do plano',
    selector: 'name',
    sortable: true,
    grow: 2,
  },
  {
    name: 'Valor',
    selector: 'value',
    sortable: true,
    format: (row: PlanItem) => `R$ ${currencyFormatterFromApi(Number(row.value))}`,
    grow: 3,
    hide: 799,
  },
];

export const navigationOptions = {
  rowsPerPageText: '',
  rangeSeparatorText: 'resultados de',
  noRowsPerPage: true,
  selectAllRowsItem: false,
  selectAllRowsItemText: '',
};

export const customStyles = {
  headCells: {
    style: {
      fontSize: 'var(--font-tiny)',
      color: 'var(--neutral-dark-pure)',
      fontWeight: 'var(--weight-semibold)',
    },
    inactiveSortStyle: {
      '&:hover:not(:focus)': {
        color: 'var(--neutral-dark-medium)',
      },
    },
  },
  pagination: {
    pageButtonsStyle: {
      'fill': 'var(--brand-primary-pure)',
      '&:disabled': {
        fill: 'var(--neutral-dark-pale)',
      },
    },
  },
};
