import { combineReducers, Reducer } from 'redux';
import { ActionTypes } from '../../actions/actionTypes';
import { Occupancy, Subscription, SubscriptionPage } from '../../model/subscription';
import { Alert } from '../../model/general';

const dialog: Reducer<Alert | null> = (state = null, { type, payload }) => {
  switch (type) {
    case ActionTypes.SUBSCRIPTION_DIALOG:
      return payload;
    case ActionTypes.LOGOUT:
    case ActionTypes.SUBSCRIPTION_CLEAN_DIALOG:
      return null;
    default:
      return state;
  }
};

const page: Reducer<SubscriptionPage | null> = (state = null, { type, payload }) => {
  switch (type) {
    case ActionTypes.SUBSCRIPTION_PAGE_LIST:
      return payload;
    default:
      return state;
  }
};

const subscription: Reducer<Subscription | null> = (state = null, { type, payload }) => {
  switch (type) {
    case ActionTypes.SUBSCRIPTION_ADD:
      return payload;
    case ActionTypes.SUBSCRIPTION_LOADING:
      return null;
    default:
      return state;
  }
};

const loading: Reducer<boolean> = (state = false, { type }) => {
  switch (type) {
    case ActionTypes.SUBSCRIPTION_LOADING:
      return true;
    case ActionTypes.SUBSCRIPTION_ERROR:
    case ActionTypes.SUBSCRIPTION_ADD:
      return false;
    default:
      return state;
  }
};

const pageLoading: Reducer<boolean> = (state = true, { type }) => {
  switch (type) {
    case ActionTypes.SUBSCRIPTION_PAGE_LOADING:
      return true;
    case ActionTypes.SUBSCRIPTION_PAGE_LIST:
      return false;
    default:
      return state;
  }
};

const pageAlert: Reducer<Alert | null> = (state = null, { type, payload }) => {
  switch (type) {
    case ActionTypes.SUBSCRIPTION_PAGE_ALERT:
      return payload;
    case ActionTypes.SUBSCRIPTION_PAGE_LOADING:
      return null;
    default:
      return state;
  }
};

const error: Reducer<Alert | null> = (state = null, { type, payload }) => {
  switch (type) {
    case ActionTypes.SUBSCRIPTION_ERROR:
      return payload;
    case ActionTypes.SUBSCRIPTION_LOADING:
      return null;
    default:
      return state;
  }
};

const occupancy: Reducer<Occupancy | null> = (state = null, { type, payload }) => {
  switch (type) {
    case ActionTypes.SUBSCRIPTION_OCCUPANCY:
      return payload;
    default:
      return state;
  }
};

const saving: Reducer<boolean> = (state = false, { type }) => {
  switch (type) {
    case ActionTypes.SUBSCRIPTION_SAVING:
      return true;
    case ActionTypes.SUBSCRIPTION_SAVED:
      return false;
    default:
      return state;
  }
};

const saved: Reducer<Alert | null> = (state = null, { type, payload }) => {
  switch (type) {
    case ActionTypes.SUBSCRIPTION_SAVED:
      return payload;
    case ActionTypes.RESET_SUBSCRIPTION_SAVED:
      return null;
    default:
      return state;
  }
};

export default combineReducers({
  dialog,
  page,
  pageLoading,
  pageAlert,
  subscription,
  loading,
  error,
  saving,
  saved,
  occupancy,
});
