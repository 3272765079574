import React, { useEffect, useState } from 'react';
import './styles.scss';

interface CountdownTimer {
  timeInSeconds: string;
  onFinish: () => void;
}

const CountdownTimer: React.FC<CountdownTimer> = ({ timeInSeconds, onFinish }) => {
  const [secondsLeft, setSecondsLeft] = useState<number>(parseInt(timeInSeconds));

  useEffect(() => {
    if (secondsLeft < 0) {
      onFinish();
    } else {
      setTimeout(() => setSecondsLeft(secondsLeft - 1), 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secondsLeft]);

  return (
    <div className="countdown-timer">
      <sb-icon class="countdown-timer__icon" icon="clock"></sb-icon>
      <div className="countdown-timer__display">00:{secondsLeft < 10 ? '0' + secondsLeft : secondsLeft}</div>
    </div>
  );
};

export default CountdownTimer;
