import React, { useState, useEffect, useRef } from 'react';
import CardResponsive from '../../../components/CardResponsive';
import Tag from '../../../components/Tag';
import { Address, SeatItem, Subscription, SubscriptionPayment } from '../../../model/subscription';
import { chairInstallStatusOptions, defaultPaymentColumns } from './_data';
import './styles.scss';
import {
  SubscriptionScreenModeEnum,
  PAYMENT_METHODS_SHOW_ONLY_HISTORY,
  CardInstallStatusValue,
  PurchaseStatusValue,
  CHAIR_NOT_EDITABLE_STATUS,
  PurchaseStatusEnum,
  CHAIR_DISABLE_EDIT_STATUS,
} from '../../../enums/subscription';
import {
  brazilianStatesOptions,
  capitalizeAll,
  cepFormatter,
  cpfCnpjFormatter,
  phoneFormatter,
  toLocaleDate,
} from 'sympla-bootstrap/utils';
import Panel from '../../../components/Panel';
import HistoryTable from '../HistoryTable';
import TextField from '../../../components/TextField';
import 'sympla-bootstrap/icon-button';
import SelectField from '../../../components/SelectField';
import ToggleSwitch from '../../../components/ToggleSwitch';
import { ignoredPaymentTypesArray } from '../HistoryTable/_data';

interface SubscriptionCardProps {
  subscription: Subscription;
  screenMode?: SubscriptionScreenModeEnum | null;
  isEditable: boolean;
  email?: string;
  phone?: string;
  currentChairEdit?: SeatItem | null;
  currentAddressEdit?: Address | null;
  installmentProcessing: number;
  isChairUpdateFailed?: boolean;
  onEmailChanged: (email: string) => void;
  onPhoneChanged: (phone: string) => void;
  onValidate: (valid: boolean) => void;
  onValidateAddress: (address: any) => void;
  onEditChair: (chair: SeatItem) => void;
  onEditAddress: (address: any) => void;
  onChairCodeChanged: (code: string) => void;
  onChairLabelChanged: (label: string) => void;
  onAddressChanged: (value: string, key: string) => void;
  onChairStatusChanged: (value: string) => void;
  onRenewStatusChanged: (value: boolean) => void;
  onReceiveInstallment: (row: SubscriptionPayment) => void;
  onFinishProcessing: () => void;
}

const SubscriptionCard: React.FC<SubscriptionCardProps> = ({
  subscription,
  screenMode,
  isEditable,
  email,
  phone,
  currentChairEdit,
  currentAddressEdit,
  installmentProcessing,
  isChairUpdateFailed,
  onEmailChanged,
  onPhoneChanged,
  onValidate,
  onValidateAddress,
  onEditChair,
  onEditAddress,
  onChairCodeChanged,
  onChairLabelChanged,
  onAddressChanged,
  onChairStatusChanged,
  onRenewStatusChanged,
  onReceiveInstallment,
  onFinishProcessing,
}) => {
  const {
    subscriberName,
    subscriberEmail,
    subscriberPhone,
    subscriptionDocument,
    subscriptionDocumentType,
    purchaseOrderId,
    termStart,
    termEnd,
    overallItemPurchaseStatus,
    recipientAddress,
    recipientAddressNumber,
    recipientAddressComplement,
    recipientPostalCode,
    recipientDistrict,
    recipientState,
    recipientCity,
    recipientName,
    renew,
    subscriptionItems,
    subscriptionPayments,
    subscriptionPlan,
    totalValue,
  } = subscription;

  const address = {
    recipientAddress,
    recipientAddressNumber,
    recipientAddressComplement,
    recipientDistrict,
    recipientState,
    recipientCity,
    recipientPostalCode,
    recipientName,
  };

  const inputChairLabelRef = useRef<SbTextFieldElement>(null);

  const [invalidEmail, setInvalidEmail] = useState<boolean>(true);
  const [invalidPhone, setInvalidPhone] = useState<boolean>(true);
  const [invalidLabel, setInvalidLabel] = useState<boolean>(true);
  const [invalidAddress, setInvalidAddress] = useState({
    recipientAddress: true,
    recipientAddressNumber: true,
    recipientAddressComplement: true,
    recipientDistrict: true,
    recipientState: true,
    recipientCity: true,
    recipientPostalCode: true,
    recipientName: true,
  });

  const latestPayment = subscriptionPayments && subscriptionPayments.length && subscriptionPayments[0];
  const paymentCardColumns = latestPayment &&
    PAYMENT_METHODS_SHOW_ONLY_HISTORY.indexOf(latestPayment.paymentMethodType) === -1 && [
      {
        paymentMethodType: latestPayment.paymentMethodType,
        cycle: latestPayment.cycle,
        installments: latestPayment.installments,
        value: totalValue,
        paymentFlow: latestPayment.paymentFlow,
      },
    ];

  const showEditButton = (purchaseStatus: PurchaseStatusEnum) => {
    return isEditable && !CHAIR_NOT_EDITABLE_STATUS.includes(purchaseStatus);
  };

  const disableEditChair = (purchaseStatus: PurchaseStatusEnum) => {
    return (
      screenMode !== SubscriptionScreenModeEnum.VIEW || CHAIR_DISABLE_EDIT_STATUS.includes(purchaseStatus)
    );
  };

  useEffect(() => {
    onValidate(invalidEmail && invalidPhone && invalidLabel);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invalidEmail, invalidPhone, invalidLabel]);

  useEffect(() => {
    onValidateAddress(invalidAddress);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invalidAddress]);

  useEffect(() => {
    if (screenMode === SubscriptionScreenModeEnum.EDIT_CHAIR) {
      inputChairLabelRef.current?.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section>
      <div className="subscription__pannels">
        <Panel
          key="name"
          label="Nome"
          value={capitalizeAll(subscriberName.toLowerCase())}
          classSta="sta-subscription-name"
          className="subscription--mobile-2"
        />
        {screenMode === SubscriptionScreenModeEnum.EDIT_OWNER_DATA ? (
          <>
            <TextField
              key="email"
              label="E-mail"
              defaultValue={email}
              className="subscription__text-field subscription--mobile-2 subscription--desktop-only-2"
              required
              type="email"
              errorMessage="E-mail inválido"
              requiredMark={false}
              onInput={onEmailChanged}
              onValidate={setInvalidEmail}
            />
            <TextField
              key="phone"
              defaultValue={phoneFormatter(phone || '')}
              label="Telefone"
              className="subscription__text-field"
              required
              format="phone"
              placeholder="(00) 00000-0000"
              errorMessage="Telefone inválido"
              requiredMark={false}
              onInput={onPhoneChanged}
              onValidate={setInvalidPhone}
            />
          </>
        ) : (
          <>
            <Panel
              key="email"
              label="E-mail"
              value={subscriberEmail}
              classSta="sta-subscription-email"
              className="subscription--mobile-2 subscription--desktop-only-2"
            />
            <Panel
              key="phone"
              label="Telefone"
              value={phoneFormatter(subscriberPhone)}
              classSta="sta-subscription-phone"
            />
          </>
        )}
        <Panel
          key="document"
          label={subscriptionDocumentType}
          value={cpfCnpjFormatter(subscriptionDocument)}
          classSta="sta-subscription-document"
        />
        <Panel
          key="subscription-number"
          label="Nº da assinatura"
          value={purchaseOrderId}
          classSta="sta-subscription-number"
        />
        <Panel
          key="initialdate"
          label="Início da assinatura"
          value={toLocaleDate(termStart)}
          classSta="sta-subscription-initialdate"
        />
        <Panel
          key="finaldate"
          label="Fim da assinatura"
          value={toLocaleDate(termEnd)}
          classSta="sta-subscription-finaldate"
        />
        <Panel
          key="state"
          label="Status"
          component={() => {
            const { tag, value } = PurchaseStatusValue[overallItemPurchaseStatus];
            return (
              <Tag appearance={tag} styled="pale">
                {value}
              </Tag>
            );
          }}
          classSta="sta-subscription-finaldate-state"
        />
      </div>
      <h3 className="subscription__title-section sta-subscription-address-title">
        Endereço de entrega
        {isEditable ? (
          <sb-icon-button
            size="micro"
            icon="pen-alt"
            onClick={() =>
              onEditAddress({
                ...address,
              })
            }
            disabled={screenMode !== SubscriptionScreenModeEnum.VIEW}
          ></sb-icon-button>
        ) : (
          ''
        )}
      </h3>

      <div className="subscription__card subscription__pannels">
        {screenMode === SubscriptionScreenModeEnum.EDIT_ADDRESS && currentAddressEdit ? (
          <>
            <TextField
              key="address"
              label="Endereço"
              defaultValue={currentAddressEdit.recipientAddress}
              required
              requiredMark={false}
              errorMessage="Endereço inválido"
              className="subscription__text-field subscription--mobile-2"
              onInput={value => onAddressChanged(value, 'recipientAddress')}
              onValidate={value => {
                setInvalidAddress({
                  ...invalidAddress,
                  recipientAddress: value,
                });
              }}
            />
            <TextField
              key="address-number"
              label="Número"
              defaultValue={currentAddressEdit.recipientAddressNumber}
              required
              requiredMark={false}
              errorMessage="Número inválido"
              className="subscription__text-field"
              onInput={value => onAddressChanged(value, 'recipientAddressNumber')}
              onValidate={value => {
                setInvalidAddress({
                  ...invalidAddress,
                  recipientAddressNumber: value,
                });
              }}
            />
            <TextField
              key="addon"
              label="Complemento"
              defaultValue={currentAddressEdit.recipientAddressComplement}
              requiredMark={false}
              className="subscription__text-field"
              onInput={value => onAddressChanged(value, 'recipientAddressComplement')}
            />
            <TextField
              key="district"
              label="Bairro"
              defaultValue={currentAddressEdit.recipientDistrict}
              required
              requiredMark={false}
              errorMessage="Bairro inválido"
              className="subscription__text-field"
              onInput={value => onAddressChanged(value, 'recipientDistrict')}
              onValidate={value => {
                setInvalidAddress({
                  ...invalidAddress,
                  recipientDistrict: value,
                });
              }}
            />
            <SelectField
              key="state"
              label="Estado"
              defaultValue={currentAddressEdit.recipientState}
              className="subscription__text-field"
              noEmptyOption
              onChange={value => onAddressChanged(value, 'recipientState')}
            >
              {brazilianStatesOptions.map(state => {
                return (
                  <option key={state} value={state}>
                    {state}
                  </option>
                );
              })}
            </SelectField>
            <TextField
              key="city"
              label="Cidade"
              defaultValue={currentAddressEdit.recipientCity}
              required
              requiredMark={false}
              errorMessage="Cidade inválida"
              className="subscription__text-field subscription--mobile-2"
              onInput={value => onAddressChanged(value, 'recipientCity')}
              onValidate={value => {
                setInvalidAddress({
                  ...invalidAddress,
                  recipientCity: value,
                });
              }}
            />
            <TextField
              key="cep"
              label="CEP"
              format="cep"
              defaultValue={cepFormatter(currentAddressEdit.recipientPostalCode!)}
              required
              requiredMark={false}
              errorMessage="CEP inválido"
              className="subscription__text-field subscription--mobile-2"
              onInput={value => onAddressChanged(cepFormatter(value), 'recipientPostalCode')}
              onValidate={value => {
                setInvalidAddress({
                  ...invalidAddress,
                  recipientPostalCode: value,
                });
              }}
            />
            <TextField
              key="ownername"
              label="Nome do destinatário"
              defaultValue={currentAddressEdit.recipientName}
              required
              requiredMark={false}
              errorMessage="Nome inválido"
              className="subscription__text-field subscription--mobile-2"
              onInput={value => onAddressChanged(value, 'recipientName')}
              onValidate={value => {
                setInvalidAddress({
                  ...invalidAddress,
                  recipientName: value,
                });
              }}
            />
          </>
        ) : (
          <>
            <Panel
              key="address"
              label="Endereço"
              value={recipientAddress}
              classSta="sta-subscription-address"
              className="subscription--mobile-2"
            />
            <Panel
              key="address-number"
              label="Número"
              value={recipientAddressNumber}
              classSta="sta-subscription-number"
            />
            <Panel
              key="addon"
              label="Complemento"
              value={recipientAddressComplement ?? '---'}
              classSta="sta-subscription-addon"
            />
            <Panel
              key="district"
              label="Bairro"
              value={recipientDistrict}
              classSta="sta-subscription-district"
              className="subscription--mobile-2"
            />
            <Panel
              key="state"
              label="Estado"
              value={recipientState}
              classSta="sta-subscription-district"
              className="subscription--mobile-2"
            />
            <Panel
              key="city"
              label="Cidade"
              value={recipientCity}
              classSta="sta-subscription-city"
              className="subscription--mobile-2"
            />
            <Panel
              key="cep"
              label="CEP"
              value={cepFormatter(recipientPostalCode)}
              classSta="sta-subscription-cep"
              className="subscription--mobile-2"
            />
            <Panel
              key="ownername"
              label="Nome do destinatário"
              value={recipientName}
              classSta="sta-subscription-ownername"
              className="subscription--mobile-2"
            />
          </>
        )}
      </div>
      <h3 className="subscription__title-section sta-subscription-chairs-title">Plano da assinatura</h3>
      <div className="subscription__chair-card">
        <div className="subscription__chair-card-row">
          <div className="subscription__chair-card-inner">
            <Panel
              key="chair-seat"
              label="Plano"
              value={subscriptionPlan.name ? subscriptionPlan.name : '-'}
            />
            <div className="subscription__panel-row">
              <div className="panel__label">Renovação automática</div>
              <ToggleSwitch
                onChange={state => onRenewStatusChanged(state)}
                defaultChecked={renew}
                disabled={
                  screenMode !== SubscriptionScreenModeEnum.VIEW ||
                  overallItemPurchaseStatus === 'CANCELED' ||
                  overallItemPurchaseStatus === 'FINISHED' ||
                  ignoredPaymentTypesArray.includes(subscriptionPayments[0].paymentMethodType)
                }
              />
            </div>
          </div>
        </div>
      </div>
      <h3 className="subscription__title-section sta-subscription-chairs-title">Cadeiras</h3>
      <div className="subscription__chair-card">
        {subscriptionItems.map(chair => {
          return (
            <div key={'chair-' + chair.exposedId} className="subscription__chair-card-row">
              <div className="subscription__chair-card-inner">
                {screenMode === SubscriptionScreenModeEnum.EDIT_CHAIR &&
                chair.exposedId === currentChairEdit?.exposedId ? (
                  <TextField
                    ref={inputChairLabelRef}
                    key="chairLabel"
                    defaultValue={currentChairEdit.label}
                    label="Nome da Placa"
                    required
                    requiredMark={false}
                    errorMessage="Nome inválido"
                    className="subscription__text-field"
                    onInput={onChairLabelChanged}
                    onValidate={setInvalidLabel}
                  />
                ) : (
                  <Panel key="chair-label" label="Nome da Placa" value={chair.label} />
                )}
                <Panel key="chair-seat" label="Assento" value={chair.seat} />
                <Panel key="chair-sector" label="Setor" value={chair.sector} />
                {screenMode === SubscriptionScreenModeEnum.EDIT_CHAIR &&
                chair.exposedId === currentChairEdit?.exposedId ? (
                  <>
                    <TextField
                      key="codeMifare"
                      format="number"
                      defaultValue={currentChairEdit.cardCodeValue || ''}
                      label="Código Mifare"
                      min={0}
                      max={14}
                      invalid={isChairUpdateFailed}
                      errorMessage="Código Mifare inválido"
                      className="subscription__text-field"
                      onInput={onChairCodeChanged}
                    />
                    <SelectField
                      label="Instalação da placa"
                      key="installStatus"
                      defaultValue={currentChairEdit.cardInstallStatus}
                      noEmptyOption
                      onChange={onChairStatusChanged}
                    >
                      {chairInstallStatusOptions.map(status => {
                        return (
                          <option key={status.key} value={status.key}>
                            {status.value}
                          </option>
                        );
                      })}
                    </SelectField>
                  </>
                ) : (
                  <>
                    <Panel key="chair-code" label="Código Mifare" value={chair.cardCodeValue || '---'} />
                    <Panel
                      key="chair-install-status"
                      label="Instalação da placa"
                      component={() => {
                        const { tag, value } = CardInstallStatusValue[chair.cardInstallStatus];
                        return (
                          <Tag appearance={tag} styled="pale">
                            {value}
                          </Tag>
                        );
                      }}
                    />
                  </>
                )}
                <Panel
                  key="chair-purchase-status"
                  label="Status da cadeira"
                  component={() => {
                    const { tag, value } = PurchaseStatusValue[chair.purchaseStatus];
                    return (
                      <Tag appearance={tag} styled="pale">
                        {value}
                      </Tag>
                    );
                  }}
                />
              </div>
              <div key="edit" className="subscription__chair-edit">
                {showEditButton(chair.purchaseStatus) ? (
                  <sb-icon-button
                    size="micro"
                    icon="pen-alt"
                    onClick={() => onEditChair(chair)}
                    disabled={disableEditChair(chair.purchaseStatus)}
                  ></sb-icon-button>
                ) : (
                  ''
                )}
              </div>
            </div>
          );
        })}
      </div>
      <h3 className="subscription__title-section sta-subscription-payment-title">Pagamento</h3>
      {paymentCardColumns && (
        <CardResponsive
          columns={defaultPaymentColumns}
          data={paymentCardColumns}
          className="grid-5-columns"
        />
      )}
      <HistoryTable
        className="history__table"
        payments={subscriptionPayments}
        screenMode={screenMode}
        onReceiveClick={onReceiveInstallment}
        installmentProcessing={installmentProcessing}
        purchaseStatus={subscription.overallItemPurchaseStatus}
        onFinishProcessing={onFinishProcessing}
      ></HistoryTable>
    </section>
  );
};

export default SubscriptionCard;
