import { Dispatch } from 'react';
import {
  SubscriptionPage,
  Subscription,
  Occupancy,
  SeatItem,
  SubscriptionFilter,
  Address,
} from '../model/subscription';
import { ActionTypes } from './actionTypes';
import { subscriptionApi } from '../api/subscription';
import { Alert, SnackbarAlert } from '../model/general';
import { subscriptionAlertMessages } from '../alertMessages';
import { AxiosError } from 'axios';

export const dialogSubscription = (payload: Alert) => ({
  type: ActionTypes.SUBSCRIPTION_DIALOG,
  payload,
});

export const cleanDialogSubscription = () => ({
  type: ActionTypes.SUBSCRIPTION_CLEAN_DIALOG,
});

export const addSubscriptions = (payload: SubscriptionPage) => ({
  type: ActionTypes.SUBSCRIPTION_PAGE_LIST,
  payload,
});

export const addSubscription = (payload: Subscription) => ({
  type: ActionTypes.SUBSCRIPTION_ADD,
  payload,
});

export const setSubscriptionLoading = () => ({
  type: ActionTypes.SUBSCRIPTION_LOADING,
});

export const setSubscriptionError = (payload: Alert) => ({
  type: ActionTypes.SUBSCRIPTION_ERROR,
  payload,
});

export const setPageListLoading = () => ({
  type: ActionTypes.SUBSCRIPTION_PAGE_LOADING,
});

export const setPageListAlert = (payload: Alert) => ({
  type: ActionTypes.SUBSCRIPTION_PAGE_ALERT,
  payload,
});

export const addOccupancy = (payload: Occupancy) => ({
  type: ActionTypes.SUBSCRIPTION_OCCUPANCY,
  payload,
});

export const setSubscriptionSaving = () => ({
  type: ActionTypes.SUBSCRIPTION_SAVING,
});

export const setSubscriptionSaved = (payload: SnackbarAlert) => ({
  type: ActionTypes.SUBSCRIPTION_SAVED,
  payload,
});

export const resetSubscriptionSaved = () => ({
  type: ActionTypes.RESET_SUBSCRIPTION_SAVED,
});

export const exportSeats = (eventId: string) => async (dispatch: Dispatch<any>) => {
  const { loading, error } = subscriptionAlertMessages.exportSeats;
  try {
    dispatch(dialogSubscription(loading));
    const { data } = await subscriptionApi.exportSeats(eventId);
    saveFile(data, 'cadeiras');

    dispatch(cleanDialogSubscription());
  } catch (e) {
    dispatch(dialogSubscription(error));
  }
};

export const exportSubscriptions = (eventId: string) => async (dispatch: Dispatch<any>) => {
  const { loading, error } = subscriptionAlertMessages.exportSubscriptions;
  try {
    dispatch(dialogSubscription(loading));
    const { data } = await subscriptionApi.exportSubscriptions(eventId);
    saveFile(data, 'assinaturas');
    dispatch(cleanDialogSubscription());
  } catch {
    dispatch(dialogSubscription(error));
  }
};

export const uploadSubscriptions = (file: File, eventId: string) => async (dispatch: Dispatch<any>) => {
  const { loading, error, invalid, success, warning } = subscriptionAlertMessages.uploadSubscriptions;
  try {
    dispatch(dialogSubscription(loading));

    const formData = new FormData();
    formData.append('data', file, file.name);

    const { data } = await subscriptionApi.uploadSubscriptions(formData, eventId);
    const alert = data.error ? warning(data) : success;

    dispatch(dialogSubscription(alert as Alert));
  } catch (err: any) {
    const { status = 500 } = err.request ?? {};
    const errorDialog = status === 400 ? invalid : error;
    dispatch(dialogSubscription(errorDialog));
  }
};

const saveFile = (file: string, name: string) => {
  const date = new Date().toLocaleDateString('pt-br').replace(/\//g, '-');
  const blob = new Blob([file], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
  });
  const link = document.createElement('a');
  link.style.display = 'none';
  document.body.appendChild(link);
  link.href = window.URL.createObjectURL(blob);
  link.download = `${name} ${date}`;
  link.click();
  link.remove();
};

export const fetchSubscriptions =
  (eventId: string, page = 0, filter: SubscriptionFilter | undefined) =>
  async (dispatch: Dispatch<any>) => {
    const { notFound, error } = subscriptionAlertMessages.page;
    try {
      dispatch(setPageListLoading());
      const regexNumberValidation = new RegExp('^[0-9]*$');
      const searchQuery =
        filter && filter.searchValue !== ''
          ? regexNumberValidation.test(filter.searchValue)
            ? `&purchaseOrderId=${filter.searchValue}`
            : `&name=${filter.searchValue}`
          : '';

      const filterStatus =
        filter && filter.purchaseStatus.length > 0
          ? filter.purchaseStatus.map(item => `&purchaseStatus=${item}`).join('')
          : '';
      const filterEndNextDays = filter && filter.endNextDays ? `&termEndNextDays=${filter.endNextDays}` : '';
      const filterChairStatus =
        filter && filter.chairStatus ? `&cardInstallStatus=${filter.chairStatus}` : '';
      const { data } = await subscriptionApi.getSubscriptions(
        eventId,
        page,
        searchQuery,
        filterStatus + filterEndNextDays + filterChairStatus,
      );
      dispatch(addSubscriptions(data));
      data.empty && dispatch(setPageListAlert(notFound));
    } catch {
      dispatch(setPageListAlert(error));
    }
  };

export const fetchSubscription = (id: string) => async (dispatch: Dispatch<any>) => {
  try {
    dispatch(setSubscriptionLoading());
    const { data } = await subscriptionApi.getSubscription(id);
    dispatch(addSubscription(data));
  } catch {
    const { error } = subscriptionAlertMessages.page;
    dispatch(setSubscriptionError(error));
  }
};

export const fetchOccupancy = (eventId: string) => async (dispatch: Dispatch<any>) => {
  try {
    const { data } = await subscriptionApi.getOccupancy(eventId);
    dispatch(addOccupancy(data));
  } catch {}
};

export const updateSubscription =
  (id: string, subscription: Subscription) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(setSubscriptionSaving());
      const { success } = subscriptionAlertMessages.updateSubscription;
      const { data } = await subscriptionApi.updateSubscriptionPhoneEmail(
        id,
        subscription.subscriberEmail,
        subscription.subscriberPhone,
      );
      dispatch(addSubscription(data));
      dispatch(setSubscriptionSaved(success as SnackbarAlert));
    } catch (e) {
      const { error } = subscriptionAlertMessages.updateSubscription;
      handleSubscriptionErrors(e as AxiosError, dispatch, error as SnackbarAlert);
    }
  };

export const updateSubscriptionChair = (id: string, chair: SeatItem) => async (dispatch: Dispatch<any>) => {
  try {
    dispatch(setSubscriptionSaving());
    const { success } = subscriptionAlertMessages.updateSubscription;
    const { data } = await subscriptionApi.updateSubscriptionChair(id, chair);
    dispatch(addSubscription(data));
    dispatch(setSubscriptionSaved(success as SnackbarAlert));
  } catch (e) {
    const { error } = subscriptionAlertMessages.updateSubscription;
    handleSubscriptionErrors(e as AxiosError, dispatch, error as SnackbarAlert);
  }
};

export const updateSubscriptionAddress =
  (id: string, address: Address) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(setSubscriptionSaving());
      const { success } = subscriptionAlertMessages.updateSubscription;
      const { data } = await subscriptionApi.updateSubscriptionAddress(id, address);
      dispatch(addSubscription(data));
      dispatch(setSubscriptionSaved(success as SnackbarAlert));
    } catch (e) {
      const { error } = subscriptionAlertMessages.updateSubscription;
      handleSubscriptionErrors(e as AxiosError, dispatch, error as SnackbarAlert);
    }
  };

export const updateSubscriptionRenew = (id: string, renew: boolean) => async (dispatch: Dispatch<any>) => {
  try {
    dispatch(setSubscriptionLoading());
    const { success } = subscriptionAlertMessages.updateSubscription;
    const { data } = await subscriptionApi.updateSubscriptionRenew(id, renew);
    dispatch(addSubscription(data));
    dispatch(setSubscriptionSaved(success as SnackbarAlert));
  } catch (e) {
    const { error } = subscriptionAlertMessages.updateSubscription;
    handleSubscriptionErrors(e as AxiosError, dispatch, error as SnackbarAlert);
  }
};

export const receiveInstallment =
  (id: string, purchaseOrderId: number, installment: number) => async (dispatch: Dispatch<any>) => {
    try {
      dispatch(setSubscriptionSaving());
      await subscriptionApi.receiveInstallment(id, purchaseOrderId, installment);
      const { info } = subscriptionAlertMessages.receiveInstallment;
      dispatch(setSubscriptionSaved(info as SnackbarAlert));
    } catch (e) {
      const { error } = subscriptionAlertMessages.receiveInstallment;
      handleSubscriptionErrors(e as AxiosError, dispatch, error as SnackbarAlert);
    }
  };

const handleSubscriptionErrors = (
  exception: AxiosError,
  dispatch: Dispatch<any>,
  defaultError: SnackbarAlert,
) => {
  const { message } =
    exception?.response?.data instanceof Array ? exception?.response?.data[0] : exception?.response?.data;
  if (message) {
    const error = { type: 'error', message };
    dispatch(setSubscriptionSaved(error as SnackbarAlert));
  } else {
    dispatch(setSubscriptionSaved(defaultError as SnackbarAlert));
  }
};
