import React from 'react';
import { OverallItemCardInstallStatusValue } from '../../../../enums/subscription';
import { SubscriptionItem } from '../../../../model/subscription';
import { toLocaleDate } from 'sympla-bootstrap/utils';
import Panel from '../../../../components/Panel';
import Tag from '../../../../components/Tag';
import './styles.scss';

interface ExpandableDetailsProps {
  data?: SubscriptionItem;
  onDetailsClick: (row: SubscriptionItem, e?: MouseEvent) => void;
}

const ExpandableDetails: React.FC<ExpandableDetailsProps> = ({ data, onDetailsClick }) => {
  if (!data) return null;
  const {
    subscriberEmail,
    subscriptionItemCount,
    overallItemCardInstallStatus,
    termStart,
    termEnd,
    purchaseOrderId,
  } = data;

  return (
    <div className="details">
      <div className="details__card">
        <Panel label="E-mail" value={subscriberEmail} className="details--column-2" />
        <Panel label="Cadeiras" value={subscriptionItemCount} />
        <Panel
          label="Instalação da placa"
          component={() => {
            const { tag, value } = OverallItemCardInstallStatusValue[overallItemCardInstallStatus];
            return (
              <Tag appearance={tag} styled="pale">
                {value}
              </Tag>
            );
          }}
        />
        <Panel label="Início da assinatura" value={toLocaleDate(termStart)} />
        <Panel label="Fim da assinatura" value={toLocaleDate(termEnd)} />
        <Panel label="Nº da assinatura" value={purchaseOrderId} />
        <sb-button class="details--column-2" onClick={() => onDetailsClick(data)} styled="outline">
          Mais Detalhes
        </sb-button>
      </div>
    </div>
  );
};

export default ExpandableDetails;
