import React from 'react';
import 'sympla-bootstrap/tag';

interface TagProps {
  appearance?: string;
  styled?: string;
  size?: string;
  className?: string;
  children: React.ReactNode;
}

const Tag: React.FC<TagProps> = ({ className, children, ...props }) => (
  <sb-tag class={className} {...props}>
    {children}
  </sb-tag>
);

Tag.defaultProps = {
  appearance: 'brand',
  styled: 'solid',
  size: 'small',
  className: '',
};

export default Tag;
