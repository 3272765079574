import React from 'react';
import 'sympla-bootstrap/spinner';

interface SpinnerProps {
  loading: boolean;
  light?: boolean;
  className?: string;
}

const Spinner: React.FC<SpinnerProps> = ({ loading, light, className }) =>
  loading ? <sb-spinner class={className} light={light}></sb-spinner> : null;

export default Spinner;
