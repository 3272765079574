import React from 'react';
import 'sympla-bootstrap/text-field';

interface TextFieldProps {
  className?: string;
  type?: string;
  label?: string;
  name?: string;
  placeholder?: string;
  required?: boolean;
  errorMessage?: string;
  defaultValue?: string;
  disabled?: boolean;
  requiredMark?: boolean;
  autocomplete?: string;
  readonly?: boolean;
  invalid?: boolean;
  noPaste?: boolean;
  min?: number;
  max?: number;
  format?: string;
  children?: React.ReactNode;
  onInput?: (value: string) => void;
  onValidate?: (valid: boolean) => void;
}

const TextField = React.forwardRef<SbTextFieldElement, TextFieldProps>(
  (
    {
      className,
      type,
      label,
      name,
      placeholder,
      required,
      errorMessage,
      defaultValue,
      disabled,
      requiredMark,
      autocomplete,
      readonly,
      invalid,
      noPaste,
      min,
      max,
      format,
      children,
      onInput,
      onValidate,
    },
    inputRef,
  ) => {
    const handlerInput = (e: Event) => {
      const input = e.target as SbTextFieldElement;
      onInput && onInput(input.plainValue);
      onValidate && onValidate(input.validate());
    };

    const handlerFocus = (e: Event) => {
      const input = e.target as SbTextFieldElement;
      onValidate && onValidate(input.validate());
    };

    return (
      <sb-text-field
        class={className}
        ref={inputRef}
        type={type}
        label={label}
        name={name}
        placeholder={placeholder}
        required={required}
        errorMessage={errorMessage}
        disabled={disabled}
        requiredMark={requiredMark}
        autocomplete={autocomplete}
        readonly={readonly}
        invalid={invalid}
        noPaste={noPaste}
        min={min}
        max={max}
        format={format}
        onInput={handlerInput}
        onFocus={handlerFocus}
        defaultvalue={defaultValue}
      >
        {children}
      </sb-text-field>
    );
  },
);

export default TextField;
