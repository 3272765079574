import React from 'react';
import { SubscriptionPayment } from '../../../../model/subscription';
import Panel from '../../../../components/Panel';
import './styles.scss';
import { toLocaleDate, toLocaleShortDate } from 'sympla-bootstrap/utils';
import {
  PaymentFlowEnum,
  PurchaseStatusEnum,
  SubscriptionScreenModeEnum,
  TransactionStatusEnum,
} from '../../../../enums/subscription';
import Button from '../../../../components/Button';
import CountdownTimer from '../../../../components/CountdownTimer';
import { ignoredPaymentTypesArray } from '../_data';
import { currencyFormatterFromApi } from '../../../../services/currencyService';

interface ExpandableDetailsProps {
  data?: SubscriptionPayment;
  screenMode?: SubscriptionScreenModeEnum | null;
  installmentProcessing: number;
  purchaseStatus: PurchaseStatusEnum;
  onReceiveClick: (row: SubscriptionPayment) => void;
  onFinishProcessing: () => void;
}

const ExpandableDetails: React.FC<ExpandableDetailsProps> = ({
  data,
  screenMode,
  installmentProcessing,
  purchaseStatus,
  onReceiveClick,
  onFinishProcessing,
}) => {
  if (!data) return null;
  const {
    referenceDate,
    paymentMethodType,
    value,
    transactionDate,
    paymentFlow,
    transactionStatus,
    installmentsCurrent,
  } = data;

  const formatedReferenceDate = (date: any, flow: any) => {
    if (flow !== PaymentFlowEnum.RECURRENCE) return '-';
    return toLocaleShortDate(date);
  };

  const formatedTransactionDate = (date: any) => {
    if (transactionDate === null || ignoredPaymentTypesArray.indexOf(paymentMethodType) !== -1) return '-';
    return `${toLocaleDate(
      date,
      {
        day: '2-digit',
        month: '2-digit',
        year: '2-digit',
      },
      'pt-BR',
    )}`;
  };

  return (
    <div className="details__history">
      <div className="details__history--card">
        <Panel label="Referência" value={formatedReferenceDate(referenceDate, paymentFlow)} />
        <Panel label="Método de pagamento" value={paymentMethodType} />
        <Panel label="Valor" value={`R$ ${currencyFormatterFromApi(value)}`} />
        <Panel
          className="details__history--card--date"
          label="Última atualização"
          value={formatedTransactionDate(transactionDate)}
        />
        {paymentFlow === PaymentFlowEnum.RECURRENCE &&
        (screenMode === SubscriptionScreenModeEnum.VIEW ||
          screenMode === SubscriptionScreenModeEnum.PROCESSING_RECEIVE_INSTALLMENT) ? (
          purchaseStatus !== PurchaseStatusEnum.CANCELED &&
          transactionStatus === TransactionStatusEnum.OVERDUE ? (
            installmentProcessing === installmentsCurrent ? (
              <CountdownTimer timeInSeconds="30" onFinish={onFinishProcessing}></CountdownTimer>
            ) : (
              <Button onClick={() => onReceiveClick(data)} disabled={installmentProcessing > 0}>
                Receber
              </Button>
            )
          ) : (
            ''
          )
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default ExpandableDetails;
