import { Alert } from './model/general';
import { DialogUploadResponse } from './model/subscription';

const loading: Alert = {
  type: 'loading',
  title: 'Por favor, aguarde',
  message: 'Estamos exportando sua lista',
};

const error: Alert = {
  type: 'error',
  title: 'Não foi possível exportar',
  message: 'Por favor, tente novamente se o problema ainda persistir.',
};

export const subscriptionAlertMessages = {
  exportSeats: {
    loading,
    error: {
      ...error,
      buttonAction: {
        text: 'Tentar de novo',
        action: 'retry_seats_export',
      },
    },
  },
  exportSubscriptions: {
    loading,
    error: {
      ...error,
      buttonAction: {
        text: 'Tentar de novo',
        action: 'retry_subscriptions_export',
      },
    },
  },
  uploadSubscriptions: {
    loading: {
      ...loading,
      message: 'Estamos recebendo seu arquivo',
    },
    error: {
      ...error,
      title: 'Não foi possível carregar',
      buttonAction: {
        text: 'Tentar de novo',
        action: 'retry_subscriptions_import',
      },
    },
    invalid: {
      ...error,
      title: 'Arquivo inválido',
      message: 'Confirme se você subiu seu arquivo corretamente no formato <strong>.xlsx</strong>',
      buttonAction: {
        text: 'Tentar de novo',
        action: 'retry_subscriptions_import',
      },
    },
    success: {
      type: 'success',
      title: 'Tudo certo!',
      message: 'Atualizamos a lista de assinaturas com sucesso.',
      buttonAction: {
        text: 'Fechar',
        action: 'close',
      },
    },
    warning: ({ updated, error: err }: DialogUploadResponse): Alert => {
      return {
        type: 'warning',
        title: 'Atualização com pendências',
        message: `Das ${
          updated + err
        } linhas, ${updated} foram atualizadas com sucesso e <strong>${err} apresentaram problemas e não foram alteradas</strong>.\n\nPor favor, modifique os campos insconsistentes no seu documento e faça o upload novamente.`,
        buttonAction: {
          text: 'Fechar',
          action: 'close',
        },
      };
    },
  },
  page: {
    notFound: {
      title: 'Sem resultados',
      message: 'Não encontramos resultados para a sua busca.',
    },
    error: {
      ...error,
      title: 'Não foi possível carregar',
      buttonAction: {
        text: 'Tentar de novo',
        action: 'retry',
      },
    },
  },
  subscription: {
    error: {
      ...error,
      title: 'Não foi possível carregar',
      buttonAction: {
        text: 'Tentar de novo',
        action: 'retry_subscription_load',
      },
    },
  },
  updateSubscription: {
    success: {
      type: 'success',
      message: 'Alterações salvas com sucesso.',
    },
    error: {
      type: 'error',
      message: 'Erro ao salvar. Tente novamente.',
    },
  },
  receiveInstallment: {
    info: {
      type: 'info',
      message: 'Solicitação enviada com sucesso. Por favor, aguarde até ser processada.',
    },
    error: {
      type: 'error',
      message: 'Erro ao enviar solicitação. Tente novamente.',
    },
  },
};

export const planMessages = {
  page: {
    notFound: {
      type: 'warning' as const,
      title: 'Você ainda não possuí planos',
      message: 'Clique no botão abaixo e crie um plano para começar a gerenciar suas assinaturas.',
      buttonAction: {
        text: 'Criar um plano',
        action: 'create_plan',
      },
    },
    error: {
      ...error,
      title: 'Não foi possível carregar',
      buttonAction: {
        text: 'Tentar de novo',
        action: 'retry',
      },
    },
  },
  createPlan: {
    success: {
      type: 'success',
      message: 'Plano criado com sucesso.',
    },
    error: {
      type: 'error',
      message: 'Erro ao salvar. Tente novamente.',
    },
  },
  updatePlan: {
    success: {
      type: 'success',
      message: 'Alterações salvas com sucesso.',
    },
    error: {
      type: 'error',
      message: 'Erro ao salvar. Tente novamente.',
    },
  },
};
