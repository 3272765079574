import React, { useCallback, useEffect, useState } from 'react';
import Iframe from 'react-iframe';
import { useHistory } from 'react-router-dom';
import './styles.scss';
import { REACT_APP_EM_EMBEDDED_URL } from '../../constants';
import { connect, useDispatch } from 'react-redux';
import { selectOrganization } from '../../actions/organizations';
import { Organization } from '../../model/organization';
import { StoreState } from '../../model/store';
import { classname } from 'sympla-bootstrap/utils';
import { User } from '../../model/session';
import { checkUser, logout } from '../../actions/session';
import { RoutesEnum } from '../../enums/routes';
import Spinner from '../../components/Spinner';

interface EventManagerProps {
  organizationSelected: Organization;
  children: React.ReactNode;
  currentPath: string;
  user: User;
  checked: boolean;
  isLogged: boolean;
}

const EventManager: React.FC<EventManagerProps> = ({
  children,
  currentPath,
  checked,
  isLogged,
  organizationSelected,
}) => {
  const dispatch = useDispatch();
  const [embeddedLoaded, setEmbeddedLoaded] = useState(false);
  const [showIframe, setShowIframe] = useState(false);
  const [loadIframe, setLoadIframe] = useState(false);
  const [iframeUrl, setIframeUrl] = useState('');
  const [eventId, setEventId] = useState();
  const [hashOrgEvent, setHashOrgEvent] = useState('');
  const history = useHistory();

  const changeRoute = useCallback((route: string) => history.push(route), [history]);

  // Adiciona o listener do event manager
  useEffect(() => {
    const eventManagerEvents = (event: any) => {
      const { message = '', value = undefined } = typeof event.data === 'object' ? event.data : {};
      switch (message) {
        case 'logout':
          dispatch(logout());
          break;
        case 'iframe-load':
          setEmbeddedLoaded(true);
          break;
        case 'route-change':
          value && changeRoute(value);
          break;
        case 'organization-change':
          value && dispatch(selectOrganization(value));
          break;
        case 'event-change':
          value && setEventId(value);
          break;
      }
    };

    window.addEventListener('message', eventManagerEvents);
    dispatch(checkUser());

    return () => window.removeEventListener('message', eventManagerEvents);
  }, [dispatch, changeRoute]);

  // Verifica se o usuário esta logado
  useEffect(() => {
    if (checked && !isLogged) {
      currentPath !== RoutesEnum.LOGIN && changeRoute(RoutesEnum.LOGIN);
      setEmbeddedLoaded(false);
    }
  }, [currentPath, isLogged, checked, changeRoute]);

  // Verifica se deve carregar o iframe
  useEffect(() => {
    const load = embeddedLoaded || RoutesEnum.EVENT_MANAGER === currentPath;

    setLoadIframe(isLogged && load);
  }, [isLogged, currentPath, embeddedLoaded]);

  // Verifica se o iframe deve ser ocultado sem desmontar o DOM
  useEffect(() => {
    setShowIframe(loadIframe && currentPath === RoutesEnum.EVENT_MANAGER);
  }, [currentPath, loadIframe]);

  useEffect(() => {
    if(isLogged) {
      document.querySelectorAll('.grecaptcha-badge').forEach(item => {
        // @ts-ignore
        if(item && item.style) {
          // @ts-ignore
          item.style.visibility = "hidden";
        }
      })
    }
  }, [isLogged]);
  

  useEffect(() => {
    if (organizationSelected) {
      const previousHash = hashOrgEvent;
      const newHash = `${organizationSelected.id}${eventId}`;
      if (previousHash !== newHash) {
        setHashOrgEvent(newHash);
      }
      if (previousHash || !eventId) {
        setIframeUrl(`${REACT_APP_EM_EMBEDDED_URL}/organization/${organizationSelected.id}/manage_presentations/all/list`);
      } else {
        setIframeUrl(`${REACT_APP_EM_EMBEDDED_URL}/organization/${organizationSelected.id}/manage_presentations/${eventId}/profile`);
      }
    } else {
      setIframeUrl(REACT_APP_EM_EMBEDDED_URL!);
    }
    // eslint-disable-next-line
  }, [organizationSelected, eventId]);

  return (
    <>
      {children}
      {loadIframe && (
        <>
          <Spinner loading={showIframe && !embeddedLoaded} className="event-manager__spinner" />

          <Iframe
            id="emEmbedded"
            className={classname('event-manager__iframe', {
              'event-manager--show': showIframe,
            })}
            title="em-embedded"
            url={iframeUrl}
          />
        </>
      )}
    </>
  );
};

const mapStateToProps = ({
  organizations: { selected },
  session: { user, checked, isLogged },
}: StoreState) => ({
  organizationSelected: selected,
  user,
  checked,
  isLogged,
});

export default connect(mapStateToProps)(EventManager);
